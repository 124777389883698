import checkPrentRowAndKeys from "./checkParentRowAndKeys";


export default function checkDisableVariantOrProperty(product_variants, properties, property_name, property_value, globallyDisabledProperties, shipsFrom) {
    const { keys, parent } = checkPrentRowAndKeys(properties)

    let disable = false
    let matchedProp = []
    if (parent) {
        disable = globallyDisabledProperties[property_name]?.includes(property_value) ?? false
    }
    else {
        product_variants.map(variant => {
            variant.variant_properties.map((prop, i) => {
                if (keys[prop.shopify_property_label] === prop.shopify_property_value || (prop.shopify_property_label === 'Ships From' && prop.shopify_property_value === shipsFrom)) {
                    matchedProp.push(true)
                }
            })
            // const lengthOfProperties = shipsFrom ? variant.variant_properties.length-1 :variant.variant_properties.length
            // console.log('lenghthhth:', lengthOfProperties);

            if (matchedProp.length === variant.variant_properties.length) {

                disable = variant.is_disabled
                matchedProp = []

                return
            }
            matchedProp = []

        })

    }

    if (disable) {
        return true
    }
    else {
        return false
    }
}