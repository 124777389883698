export default function checkDisableShipsFromOnEditView({ 
    product_variants,
    property_name,
    property_value,
    shipsFrom ,
 }) {

    let disable = false
    let matchedProp = []

        product_variants.map(variant => {
            variant.variant_properties.map((prop, i) => {
                
                if (prop.shopify_property_label ===  property_name && prop.shopify_property_value===property_value || (prop.shopify_property_label === 'Ships From' && prop.shopify_property_value === shipsFrom)) {
                    matchedProp.push(true)
                }

            })

            if (matchedProp.length === 2) {

                disable = variant.is_disabled
                matchedProp = []

                return
            }
            matchedProp = []

        })

    if (disable) {
        return true
    }
    else {
        return false
    }
}