import React, { useState } from "react";
import greenbullet from "../../../assets/greenbullet.svg";
import { parseBulletPoints } from "../../../Utilities/ParseBulletPoints";
import { useSelector } from "react-redux";
import canvaIcon from '../../../assets/canva.svg'
 
const FourthContentBox = ({
  content,
  handleSectionEditChange,
  image,
  section3Image,
  bulletBenefits,
}) => {
  const { productBulletBenefits } = useSelector(
    (store) => store?.productVariants
  );
  const [isHovered, setIsHovered] = useState(false);

  const handleSectionImageClick = () => {
    handleSectionEditChange(["section3Image"]);
  };

  return (
    <div className="first-content-box-main-container">
      <div
        onClick={() => handleSectionEditChange(["bulletBenefits"])}
        style={{ cursor: "pointer" }}
        className="second-content-box-left-container"
      >
        <div
          className={`third-container-left-box ${
            bulletBenefits ? "outline-border-selected" : "outline-border"
          }`}
        >
          <ul className="product-bullet-points-list">
            {content
              ? parseBulletPoints(productBulletBenefits)?.map((point, index) => (
                  // ? content?.map((point, index) => (
                  <li key={index} className="product-bullet-point-item">
                    <img
                      src={greenbullet}
                      alt=""
                      className="productbullet-point-icon"
                    />
                    <span className="product-bullet-point-text">{point}</span>
                  </li>
                ))
              : ""}
          </ul>
        </div>
      </div>
      <div
        onClick={handleSectionImageClick}
        style={{ cursor: "pointer", width: "50%" }}
        className={`first-content-box-right-container" ${
          section3Image ? "outline-border-selected" : "outline-border"
        }`}
      >
        {image && (
          
          <div onMouseEnter={() => {
            setIsHovered(true);
          }}
            onMouseLeave={() => {
              setIsHovered(false);
            }}
            style={{ position: 'relative', display: 'inline-block', cursor: 'pointer',margin:0, height:'600px' }}>
            <img

              // onClick={handleSectionImageClick}
              style={{ cursor: "pointer" }}
              className="first-content-box-right-container-image !mb-0"
              src={image}
              alt=""
            />
            {isHovered && (
              <div
                className="background-gallery-image-overlay-edit-image-section  !rounded-none"
                style={{
                  position: 'absolute',
                 
                }}
              >
                <div className="edit-image-upper-portion !items-center !justify-between"></div>
                <div className="edit-image-lower-portion">
                  <button
                    className="gallery-image-edit-button bg-white !w-auto !py-0 !pl-0 !pr-2 !h-[40px] !m-0 !justify-start hover:!bg-[#F4F6F7]"
                    onClick={handleSectionImageClick}
                  >
                    <img className="m-[8px]" width="125px" src={canvaIcon} alt="Sync Canva edits" />
                    <p className="text-[13px] font-semibold">Change media</p>
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default FourthContentBox;
