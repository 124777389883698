import React, { useEffect, useState } from "react";

export default function LazyBackgroundImg({
  img,
  children,
  className,
  style,
  isDarkened,
  cached
}) {
  const [loaded, setLoaded] = useState(false);
  const [prevImg, setPrevImg] = useState(img);

  useEffect(() => {
    if (img !== prevImg &&!cached) {
      setLoaded(false);   
    }else{
    }
  }, [img]);

  const handleLoad = () => {
    console.log('loaded=>');
    
    setLoaded(true);
    setPrevImg(img)

  };

  return (
    <div
      className={className}
      style={{ 
        backgroundImage: `url(${loaded ? img : prevImg})`,
        filter: loaded ? "none" : "blur(5px)",
        transition: "filter 0.3s",
        ...style,  
      }}
    >
       <img src={img} alt="" onLoad={handleLoad} style={{ display: "none" }} />
      
      {loaded && children}
    </div>
  );
}
