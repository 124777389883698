import React, { useEffect, useState } from "react";
import { message, Modal } from "antd";
// import modaldownloadicon from "../../../assets/modaldownloadicon.svg";
import trashbin from "../../../assets/trashbin.svg";

import { callBackendAPI } from "../../../helpers/apiCalls";
import CustomLoader from "../../CustomLoader";

const DeleteProductModal = ({ OpenModal, setOpModal, handleDeleteFromEnd }) => {

  const { product_id: productId, product_title: productName } = OpenModal?.productDetails

  console.log(productId, productName, OpenModal, 'clicked id of product');
  const [deleteLoading, setDeleteLoading] = useState(false);

  useEffect(() => {

    if (document.getElementById('link-input')) {
      document.getElementById('link-input').focus()
    }
    const modal = document.querySelector('.ant-modal-content')
    if (modal) {
      modal.setAttribute('style', 'padding: 20px !important;');

    }
  }, [OpenModal]);


  const ConfirmButton = () => {
    return (
      <span className="">
        {!deleteLoading ? (
          "Delete product"
        ) : (
          <span className="flex justify-center items-center gap-2">
            <p>
              Deleting product
            </p>
            <CustomLoader width='20px' />
          </span>
        )}
      </span>
    );
  };

  const handleCancel = () => {
    setOpModal({ open: false, productDetails: {} });

  };
  const handleConfirm = async () => {
    setDeleteLoading(true);

    //calling api
//  as as as as as as
    try {
      // Make API call to backend
      const response = await callBackendAPI(
        "store/product/delete",
        "POST",
        {}, { payload: { id: productId } } // Pass your request body here
      );

      if (!response?.error && response?.status) {
        setDeleteLoading(false)
        message.success('Product deleted successfully!')
        handleCancel()

      } else {
        setDeleteLoading(false)
        return message.error( response?.message || 'Something went wrong while deleting the product!' )
      }
    } catch (error) {
      setDeleteLoading(false)
      console.log('api failed:', error);
      return message.error(`api failed: ${error.message} `)
    } finally {
      handleDeleteFromEnd()
      setDeleteLoading(false)
      handleCancel()
    }

  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleConfirm();
    }
  };

  return (
    <div>
      <Modal
        closable={false}
        open={OpenModal}
        onOk={handleConfirm}
        onCancel={handleCancel}
        okText={<ConfirmButton />}
        cancelText="Cancel"
        okButtonProps={{
          className: deleteLoading
            ? "delete-btn-delete-prod-modal delete-btn-delete-prod-modal-disabled"
            : "delete-btn-delete-prod-modal",
          disabled: deleteLoading,
        }}
        cancelButtonProps={{
          className: deleteLoading
            ? "product-modal-cancel-button-disabled"
            : "product-modal-cancel-button",
          disabled: deleteLoading,
        }}
      >
        <div className="delete-product-modal-main-container">
          <span
            className="product-delete-btn !w-[60px] !h-[60px]">
            <img className="w-[50px]" src={trashbin} alt="trashbin" />
          </span>
          <div className="delete-prduct-modal-content">
            <h4>Delete product</h4>
            <p>Do you really want to delete <b>{productName + " "}</b>
              from Sofia and Shopify?</p>
          </div>

        </div>
      </Modal>
    </div>
  );
  // 
};

export default DeleteProductModal;
