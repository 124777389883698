
export default function getUpdatedArraysAfterCanvaEditing({ productImages, sectionsImages, imageUrl, updatedUrl }) {
    // Update sectionImages
    const keysToUpdate = Object.keys(sectionsImages).filter(key => sectionsImages[key] === imageUrl);
    const updatedSectionsImages = { ...sectionsImages };
    keysToUpdate.forEach(key => {
        updatedSectionsImages[key] = updatedUrl;
    });

    // Update productImages
    const updatedProductImages = productImages.map(image => (image === imageUrl ? updatedUrl : image));

    return { updatedProductImages, updatedSectionsImages };
}


