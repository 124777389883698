import React, { useState } from "react";
import NoDataContainer from "../../../assets/NoDataContainer.svg";
import importIcon from "../../../assets/importIcon.svg";
import AddProductModal from "../AddProductModal";
import CustomLoader from "../../CustomLoader";

const NoDataComponent = ({handler,authorizationCheckLoading}) => {
   return (
    <div className="no-data-component-main-container">
      <img style={{ marginBottom: "16px" }} src={NoDataContainer} alt="" />
      <h4>Start by importing a product</h4>
      <p className="mt-2">
        Any products imported from AliExpress will live here.
      </p>
      <p className="lower-start-selling-p mb-5">
        Start selling by importing a product.
      </p>
      <button className="import-product-btn" type="button" onClick={handler}>
      {authorizationCheckLoading ? "" : "Import product"}
          {authorizationCheckLoading ? <CustomLoader width="20px" color="white" /> : <img src={importIcon} alt="icon" />}
      </button>
     </div>
  );
};

export default NoDataComponent;
