 

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useSearchParams } from 'react-router-dom';
import { withAuthenticator } from '@aws-amplify/ui-react';
import Dashboard from './Components/Dashboard';
import ManageOrders from './Components/ManageOrders/ManageOrders';
import MyProducts from './Components/MyProducts/MyProducts';
import ProductEditViewLoading from './Components/ProductEditView/ProductEditViewLoadingScreen';
import ImportingLoading from './Components/ProductEditView/ImportingProductLoading';
import CopyRightPage from './Components/CopyRight/CopyRightPage';
import './App.css';
import currentAuthenticatedUser, { getCurrentAuthenticatedSession } from './authUser';
import { URLS } from './URLS';
import { message } from 'antd';
import { useDispatch } from 'react-redux';
import { setStoreInformation } from './redux/slices/auth-user-slice';

const App = () => {

  
  return (
    <Router>
      <Routes>
        {/* Public Route */}
        <Route path="/privacy" element={<CopyRightPage />} />

        {/* Protected Routes */}
        <Route path="/*" element={<AuthenticatedApp />} />
      </Routes>
    </Router>
  );
};

// Wrap only the authenticated routes with withAuthenticator
const AuthenticatedApp = withAuthenticator(() => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch()
  useEffect(() => {

    const checkShopifyConnection = async () => {
      let shopParam = searchParams.get("shop");

      if (!shopParam) {
        shopParam = "";
      }
      const { userId, loginId } = await currentAuthenticatedUser(); // Await to get the resolved value
      const accessToken = await getCurrentAuthenticatedSession();
      try {
        const response = await fetch(
          `${URLS.BACKEND_API}/store/auth_check?shop=${shopParam}&userId=${userId}&cognito_email=${encodeURIComponent(loginId)}`,
          {
            method: "GET",
            headers: userId
              ? {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`, // Replace with your auth token format
              }
              : {
                "Content-Type": "application/json",
              },
          }
        );
        const data = await response.json();
        localStorage.setItem("email", data?.storeData?.email);
        localStorage.setItem("shop_owner", data?.storeData?.shop_owner);
        localStorage.setItem("shopifyConnected", data.connected);
        localStorage.setItem("shopName", data.storeData.shop);

        // saving the shops owner and email in redux store
        const shopifyConnectionDetails = {
          connected: data.connected,
          name: data.storeData.shop
        }

        dispatch(setStoreInformation({ shop_owner: data?.storeData?.shop_owner, email: data?.storeData?.email, shopifyConnectionDetails }))

        if (data.token) {
          localStorage.setItem("token", data.token);
        }

        if (data.connected) {
          localStorage.setItem("shopName", data.storeData.shop);
          localStorage.setItem("shopId", data.storeData.shop_id);

        } else if (data.error) {

        } else {
        }
      } catch (error) {
        message.error('Unexpected error occured while getting Shopify details!')

      } finally {
      }
    };

    checkShopifyConnection();
    // checkAliExpressConnection();
  }, []);
  return (
    <Routes>
      <Route path="/" element={<MyProducts />} />
      <Route path="/settings" element={<Dashboard />} />
      <Route path="/orders" element={<ManageOrders />} />
      <Route path="/edit-product/:id" element={<ProductEditViewLoading />} />
      <Route path="/edit-products/:id" element={<ImportingLoading />} />
    </Routes>
  );
});

export default App;
