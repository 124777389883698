import React, { useEffect, useState } from "react";
import DOMPurify from "dompurify";
import canvaIcon from '../../../assets/canva.svg'
const FourthContentBox = ({
  text,
  heading,
  handleSectionEditChange,
  image,
  section4Image,
  salesTextHeadline3,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [content, setcontent] = useState();
  useEffect(() => {
    setcontent({
      text: text,
      heading: heading,
    });
  }, [text, heading]);
  const handleSectionImageClick = () => {
    handleSectionEditChange(["section4Image"]);
  };
  const sectionText = DOMPurify.sanitize(content?.text);
  return (
    <div className="first-content-box-main-container">
      <div
        onClick={handleSectionImageClick}
        style={{ cursor: "pointer" }}
        className={`first-content-box-right-container ${
          section4Image ? "outline-border-selected" : "outline-border"
        }`}
      >
        {image && (
          // <img
          //   className="first-content-box-right-container-image"
          //   src={image}
          //   alt=""
          // />
          <div onMouseEnter={() => {
            setIsHovered(true);
          }}
            onMouseLeave={() => {
              setIsHovered(false);
            }}
            style={{ position: 'relative', display: 'inline-block', cursor: 'pointer',margin:0, height:'600px' }}>
            <img

              // onClick={handleSectionImageClick}
              style={{ cursor: "pointer" }}
              className="first-content-box-right-container-image !mb-0"
              src={image}
              alt=""
            />
            {isHovered && (
              <div
                className="background-gallery-image-overlay-edit-image-section  !rounded-none"
                style={{
                  position: 'absolute',
                 
                }}
              >
                <div className="edit-image-upper-portion !items-center !justify-between"></div>
                <div className="edit-image-lower-portion">
                  <button
                    className="gallery-image-edit-button bg-white !w-auto !py-0 !pl-0 !pr-2 !h-[40px] !m-0 !justify-start hover:!bg-[#F4F6F7]"
                    onClick={handleSectionImageClick}
                  >
                    <img className="m-[8px]" width="125px" src={canvaIcon} alt="Sync Canva edits" />
                    <p className="text-[13px] font-semibold">Change media</p>
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="second-content-box-left-container">
        <div
          onClick={() =>
            handleSectionEditChange(["salesTextHeadline3", "salesText3"])
          }
          style={{ cursor: "pointer" }}
          className={`fourth-container-left-box ${
            salesTextHeadline3 ? "outline-border-selected" : "outline-border"
          }`}
        >
          {content?.heading ? <h3>{content?.heading}</h3> : ""}
          {content?.text ? (
            <div className="edit-formatted-text">
              <div
                style={{ color: "#373b42" }}
                dangerouslySetInnerHTML={{ __html: sectionText }}
              ></div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="fourth-container-left-box">
          <button className="product-shop-now-button-black">SHOP NOW</button>
        </div>
      </div>
    </div>
  );
};

export default FourthContentBox;
