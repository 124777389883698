import React, { useEffect, useState } from "react";
import { Tabs, Table, Spin, Tooltip, Dropdown, Select, message, Button, notification, Space } from "antd";
import importIcon from "../../../assets/importIcon.svg";
import "./OrdersTable.css";
import NoDataComponent from "../NoDataComponent";
import { callBackendAPI } from "../../../helpers/apiCalls";
import { LoadingOutlined } from "@ant-design/icons";
import TablePagination from "../../Commons/TablePagination";
import currentAuthenticatedUser from "../../../authUser";
import { Link, Navigate } from "react-router-dom";
import arrowIcon from "../../../assets/arrow-icon.svg";
import circleIcon from "../../../assets/circle-icon.svg";
import alertIcon from "../../../assets/alert-icon.svg";
import arrowDownIcon from "../../../assets/arrow-down-icon.svg";
import countryCodes from "./countryCodes.json";
import currencyCodes from "./currencyCodes.json";
import check from "../../../assets/check.svg";
import filterIcon from "../../../assets/filters-icon.svg";
import RenderMoreItem from "./RenderMoreItem";
import OrderFullfilmentSidePage from "../ReplaceVariantSidePage/OrderFullfilmentSidePage";
import { data } from "autoprefixer";
import { useDispatch, useSelector } from "react-redux";
import { refreshOrdersTableController, setBlockedLowStockOrders, setOrderRecord } from "../../../redux/slices/replace-variant-slice";
// import arrowIcon from "../../../assets/back-arrow-grey.svg";

import { URLS } from "../../../URLS";
const { Option } = Select;

function getOrderStatus(lineItems) {
  if (lineItems.length === 0) {
    return {
      status: "blocked",
      errorType: "no-items",
      message: "No items",
      button: {
        message: "Fix now",
      },
    };
  }
  // blocked status logic
  // B_DROPSHIPPER_DELIVERY_ADDRESS_VALIDATE_FAIL
  // INVENTORY_HOLD_ERROR
  // DELIVERY_METHOD_NOT_EXIST"
  else if (
    lineItems.some((item) => item.aliexpress_shipping_status === "blocked")
  ) {
    if (lineItems.some((item) => item.blocked_details === "SKU_NOT_EXIST" || item.blocked_details === "INVENTORY_HOLD_ERROR")) {
      // INVENTORY_HOLD_ERROR
      console.log('SKU_NOT_EXIST');
      return {
        status: "blocked",
        errorType: "no-stock",       //lowStock , incompleteInfo , missingShipping
        message: "Missing stock",
        button: {
          message: "Fix now",
        },
      };
    }
    if (lineItems.some((item) => item.blocked_details === "B_DROPSHIPPER_DELIVERY_ADDRESS_VALIDATE_FAIL")) {
      console.log('B_DROPSHIPPER_DELIVERY_ADDRESS_VALIDATE_FAIL');
      return {
        status: "blocked",
        errorType: "incomplete-info",
        message: "Incomplete information",
        button: {
          message: "Fix now",
        },
      };

    }
    if (lineItems.some((item) => item.blocked_details === "DELIVERY_METHOD_NOT_EXIST")) {
      console.log('DELIVERY_METHOD_NOT_EXIST');

      return {
        status: "blocked",
        errorType: "missing-shipping-method",
        message: "Missing shipping method",
        button: {
          message: "Fix now",
        },
      };

    }

    return {
      status: "unknown",
      message: "Unknown status",
      button: {
        message: "Contact support",
      },
    };

  }

  else if (
    lineItems.some((item) => item.aliexpress_shipping_status === "pending")
  ) {
    return {
      status: "pending",
      message: "Pending",
      button: {
        message: "Create AliExpress order",
      },
    };
  } else if (
    lineItems.some((item) => item.aliexpress_shipping_status === "OrderCreated")
  ) {
    return {
      status: "payment",
      message: "Awaiting payment",
      button: {
        message: "Pay",
      },
    };
  } else if (
    lineItems.some((item) => item.aliexpress_shipping_status === "PaymentAuthorized")
  ) {
    return {
      status: "shipment",
      message: "Awaiting shipment",
    };
  } else if (
    lineItems.some((item) => item.aliexpress_shipping_status === "OrderShipped")
  ) {
    return {
      status: "shipped",
      message: "Shipped",
    };
  } else if (
    lineItems.some((item) => item.aliexpress_shipping_status === "delivered")
  ) {
    return {
      status: "delivered",
      message: "Delivered",
    };
  } else {
    return {
      status: "unknown",
      message: "Unknown status",
      button: {
        message: "Contact support",
      },
    };
  }
}

async function createAliExpressOrders(orderIds) {
  return new Promise((resolve) => {
    try {
      callBackendAPI(
        `orders/create-order`,
        "POST",
        {},
        {
          payload: {
            orderIds,
          },
        },
        null
      ).then(resolve);
    } catch (error) {
      resolve({ status: false, message: error.message });
    }
  });
}

async function doBulkAction(action, records) {
  const orderIds = records.map((record) => record.order_id);

  if (!orderIds.length) {
    return;
  }

  return new Promise((resolve) => {
    try {
      if (action === "move-to-payment") {
        callBackendAPI(
          `orders/create-orders`,
          "POST",
          {},
          {
            payload: {
              orderIds,
            },
          }
        )
          .then((data) => {
            resolve({
              data,
              orderIds,
            });
          })
          .catch((error) => {
            console.error('API call failed:', error);
            resolve({ data: { status: false, message: error.message } });

          });
      }
      else if (action === "pay-order") {

        return window.open(`https://www.aliexpress.com/p/order/index.html?`, '_blank')

      }
    } catch (error) {
      resolve({ data: { status: false, message: 'Api failed!' } });
    }
  });
}

function redirectToAliExpressToPay(record) {

  if (!record) {
    return
  }
  const { line_items } = record
  const id = line_items[0].aliexpress_order_id

  window.open(`https://www.aliexpress.com/p/order/detail.html?spm=a2g0o.order_list.order_list_main.1.24fe1802pVaCgj&orderId=${id}`, '_blank')





  // if (!aliExpressOrderIds.length) {
  //   return;
  // }

  // return new Promise((resolve) => {
  //   try {
  //     if (action === "move-to-payment") {
  //       callBackendAPI(
  //         `orders/create-orders`,
  //         "POST",
  //         {},
  //         {
  //           payload: {
  //             orderIds,
  //           },
  //         }
  //       ).then((data) =>
  //         resolve({
  //           data,
  //           orderIds,
  //         })
  //       );

  //     }
  //   } catch (error) {
  //     resolve({ status: false, message: error.message });
  //   }
  // });
}
const items = [

  {
    key: "move-to-payment",
    label: "Create AliExpress orders",
  },
  {
    key: "pay-order",
    label: "Pay Orders",
  },

];


const OrdersTable = () => {

  const dispatch = useDispatch()
  const [orders, setOrders] = useState([]);
  const [wholeData, setWholeData] = useState([]);
  // const [blockedOrdersLowStock, setBlockedOrdersLowStock] = useState([]);

  const [orderCounts, setOrderCounts] = useState({});
  const [paginationNumber, setPaginationNumber] = useState(1);
  const [rowsPerPageNumber, setRowsPerPageNumber] = useState(10);
  const [tab, setTab] = useState("all");
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [rowKeys, setRowKeys] = useState([]);
  const [moreItem, setMoreItem] = useState(null);
  const [filterBy, setFilterBy] = useState('Filters');
  const [DrawerOpen, setDrawerOpen] = useState(false);
  const [incorrectShipping, setIncorrectShipping] = useState(false);
  const [lineItems, setLineItems] = useState([{
    "id": 48,
    "order_id": "32323232",
    "shop_id": "57820151921",
    "product_id": "7214134493297",
    "aliexpress_product_sku": "14:350853#FW-ET507-420-8BH;200007763:100015000",
    "quantity": 1,
    "created_at": "2024-07-29T14:43:19.031Z",
    "updated_at": "2024-07-29T14:43:19.031Z",
    "shopify_shipping_status": "pending",
    "aliexpress_shipping_status": "pending",
    "aliexpress_order_id": null,
    "aliexpress_details": {
      "aliexpress_product_id": "4001345261178",
      "aliexpress_product_sku": "14:350853#FW-ET507-420-8BH;200007763:100015000"
    }
  }]);

  const MenuLabel = ({ name, count }) => {
    return (<div className="tab-label-container justify-between">
      <div>{name}</div>
      <div className="flex items-center gap-2">

        <span className="orders-number-container">
          {count}
        </span>
        {name === moreItem && <img src={check} alt="checked" />}
      </div>
    </div>)
  }

  const moreActionItems = [
    {
      key: "Awaiting Shipment",
      label: <MenuLabel name="Awaiting Shipment" count={orderCounts.shipment} />
    },
    {
      key: "Shipped",
      label: <MenuLabel name="Shipped" count={orderCounts.shipped} />
    },
    {
      key: "Delivered",
      label: <MenuLabel name="Delivered" count={orderCounts.delivered} />
    },
    {
      key: "Cancelled",
      label: <MenuLabel name="Cancelled" count={orderCounts.cancelled} />
    },
  ]
  const filtersItems = [
    {
      key: "Filter by Customer",
      label: "Filter by Customer"
    },
    {
      key: "Filter by Date",
      label: "Filter by Date"
    },
  ]
  const orderTabs = [
    {
      key: "all",
      label: (
        <div className="tab-label-container">
          <div>All orders</div>
          <span className="orders-number-container">
            {orderCounts.all}

          </span>
        </div>
      ),
    },
    {
      key: "blocked",
      label: (
        <div className="tab-label-container">
          <div>Blocked orders</div>
          <span
            className={`orders-number-container ${!!+orderCounts.blocked && "error"
              }`}
          >
            {orderCounts.blocked}
          </span>
        </div>
      ),
    },
    {
      key: "pending",
      label: (
        <div className="tab-label-container">
          <div>Pending orders</div>
          <span className="orders-number-container">
            {orderCounts.pending}
          </span>
        </div>
      ),
    },
    {
      key: "OrderCreated",
      label: (
        <div className="tab-label-container">
          <div>Awaiting payment</div>
          <span className="orders-number-container">
            {orderCounts.OrderCreated}
          </span>
        </div>
      ),
    },
    {
      key: "More",
      label: (
        <div className="tab-label-container">
          <Dropdown
            menu={{
              items: moreActionItems,
              onClick: (menuItem) => {
                setMoreItem(menuItem.key)
                setTab(menuItem.key === 'Awaiting Shipment' ? 'shipment' : (menuItem.key).toLowerCase())
              },
            }}
            placement="bottomLeft"
          >
            <div className="actions-btn flex gap-2">
              <span>
                {
                  moreItem ? <RenderMoreItem moreItem={moreItem}
                    value={moreItem === 'Awaiting Shipment' ? orderCounts.shipment : orderCounts[(moreItem).toLowerCase()]} /> : 'More'
                }
              </span>
              <img src={arrowDownIcon} alt="arrow down icon" />
            </div>
          </Dropdown>
        </div>
      ),
    },
  ];

  const handleBlockedOrders = (status, record) => {
    dispatch(setOrderRecord(record))

    const { errorType } = status

    // for items with low stock
    if (errorType === 'no-stock' || errorType === 'missing-shipping-method') {
      const { line_items } = record
      const problematicSkus = line_items.filter(i => (i.blocked_details === 'DELIVERY_METHOD_NOT_EXIST' ||
        i.blocked_details === 'SKU_NOT_EXIST' || i.blocked_details === "INVENTORY_HOLD_ERROR"))
      setLineItems(problematicSkus)
      setDrawerOpen(true)
      setIncorrectShipping(false)
    }

    // for items with missing shipping method  

    if (errorType === 'missing-shipping-method') {
      // setDrawerOpen(true)
      // setIncorrectShipping(true)
      // missing-shipping-method
    }

    // for items with missing information
    if (errorType === 'incomplete-info') {
      setDrawerOpen(true)
      setIncorrectShipping(true)
      // incomplete-info
    }

  }

  const columns = [
    {
      title: "Order ID",
      dataIndex: "order_number",
      key: "id",
      width: "100px",
      render: (id, record) => {
        return (
          <Link
            to={`https://admin.shopify.com/store/${localStorage
              .getItem("shopName")
              .match(/.+?(?=\.myshopify\.com)/)}/orders/${record.order_id}`}
            className="order-id-link"
            target="_blank"
          >
            #{id}
          </Link>
        );
      },
    },
    {
      title: "Order status",
      dataIndex: "status",
      key: "status",
      width: "300px",
      render: (status, record, index) => {
        return (
          <div
            className={`order-status ${status.status}`}
            data-error-type={status.errorType}
            //TODO: Add onClick event for the buttons
            onClick={async () => {
              dispatch(setOrderRecord(record))
              if (!status.button) {
                return;
              }

              if (status.status === "pending") {
                setLoading(true);
                try {

                  const response = await callBackendAPI(
                    "aliexpress/check-access-validity",
                    "GET",
                    {
                    }
                  );

                  if (!response?.status) {
                    openNotification()
                    setLoading(false);

                  } else {

                    doBulkAction("move-to-payment", [record])
                      .then((response) => {

                        const { data } = response
                        if (!data?.status) {

                          if (data?.authorize) {
                            openNotification()
                            return;
                          }
                          return message.error(data?.message, 3);
                        }
                        message.success(data?.message, 3)
                        //   setOrders((prevOrders) => {
                        //     const newOrders = [...prevOrders];
                        //     const updatedOrderIndex = newOrders.findIndex(
                        //       (order) => +order.order_id === +record.order_id
                        //     );
                        //     const updatedOrder = newOrders[updatedOrderIndex];

                        //     response.orderIds.forEach((orderId) => {
                        //       updatedOrder.line_items.forEach((lineItem) => {
                        //         lineItem.aliexpress_shipping_status = "payment";
                        //         lineItem.aliexpress_order_id = "changed";
                        //       });

                        //       updatedOrder.status = getOrderStatus(
                        //         updatedOrder.line_items
                        //       );
                        //     });

                        //     return newOrders;
                        //   });

                        //   setOrderCounts((prevOrderCounts) => {
                        //     const newOrderCounts = { ...prevOrderCounts };
                        //     newOrderCounts.pending =
                        //       +newOrderCounts.pending - response.orderIds.length;
                        //     newOrderCounts.payment =
                        //       +newOrderCounts.payment + response.orderIds.length;
                        //     return newOrderCounts;
                        //   });

                        //   message.success(data?.message, 3)
                      })
                      .finally(() => {
                        refreshOrders();
                        setLoading(false);
                      });
                  }
                } catch (error) {
                  setLoading(false);

                  console.error('API call failed:', error);
                  message.error(error.message, 3);
                }
              }
              if (status.status === 'payment') {
                redirectToAliExpressToPay(record)
              }
              else if (status.status === 'blocked') {
                handleBlockedOrders(status, record)

              }
            }}
          >
            <img className="circle-icon" src={circleIcon} alt="circle icon" />
            <span>{status.message}</span>
            {status.button && (
              <div className="button">
                <span>{status.button.message}</span>
                <img
                  className=" arrow-icon"
                  src={arrowIcon}
                  alt="arrow pointing right"
                />
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: "Order date",
      dataIndex: "created_at",
      key: "createdAt",
      width: "170px",
      render: (date) => {
        const daysCount = Math.floor(
          (new Date() - new Date(date)) / (1000 * 60 * 60 * 24)
        );

        return (
          <div className="order-date">
            <span>{new Date(date).toLocaleDateString("en-GB")}</span>
            <span className="days-count">{`${daysCount > 0 ? daysCount : ""}${daysCount === 1
              ? " day"
              : daysCount === 0
                ? "Less than 1 day ago"
                : " days"
              } ${daysCount > 0 ? " ago" : ""}`}</span>
          </div>
        );
      },
    },
    {
      title: "Number of items",
      dataIndex: "items_count",
      key: "itemsCount",
      width: "100px",
      render: (count, record) => {
        return (
          <div className="items-count-cell">
            <span>{count}</span>
            {record.includes_missing_products && (
              <Tooltip
                placement="top"
                title="This order includes products not imported by Sofia. You must fulfill these products manually!"
                arrow={false}
                color="#FEF3F2"
              >
                <img className="alert-icon" src={alertIcon} alt="alert icon" />
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      width: "100px",
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "total",
      width: "200px",
    },
    {
      title: "Destination",
      dataIndex: "destination",
      key: "destination",
      width: "250px",
    },
  ];

  const refreshOrders = (abortController = {}) => {
    setLoading(true);
    callBackendAPI(
      `orders/all`,
      "GET",
      {
        shopId: localStorage.getItem("shopId"),
        status: tab,
        page: paginationNumber,
        limit: rowsPerPageNumber,
      },
      null,
      abortController.signal
    )
      .then((response) => {
        if (!response?.status) {
          return;
        }

        setOrderCounts(response.counts);

        response.orders.forEach((order) => {
          order.status = getOrderStatus(order.line_items);
          order.items_count = order.line_items.reduce(
            (acc, item) => acc + item.quantity,
            0
          );
          order.destination =
            countryCodes[order.country_code] || order.country_code;
          order.total = `${currencyCodes[order.currency]?.symbol || "$"} ${order.subtotal_price
            }`;
        });

        setOrders(response.orders);
        setWholeData(response.orders)
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onTabChange = (key) => {
    if (key === 'More') {
      return
    }
    setTab(key);
    setPaginationNumber(1);
  };

  useEffect(() => {

    if (orders.length > 0) {
      const blockedLowStock = []
      orders.map((order, index) => {
        // destructure
        const { line_items } = order
        const { aliexpress_details } = line_items;
        // const { aliexpress_product_id, aliexpress_product_sku } = aliexpress_details
        const withLowStockLineItems = line_items.filter(item => (item.aliexpress_shipping_status === 'blocked' &&
          (item.blocked_details === 'SKU_NOT_EXIST' || item.blocked_details === 'INVENTORY_HOLD_ERROR')))

        if (withLowStockLineItems.length > 0) {
          blockedLowStock.push({
            order_id: order.order_id,
            order_number: order.order_number,
            orderDate: order.created_at,
            total: order.total,
            items: withLowStockLineItems,
          })
        }
      })
      dispatch(setBlockedLowStockOrders(blockedLowStock))
    }

  }, [orders])

  useEffect(() => {
    setTimeout(() => {

      document.querySelectorAll('.ant-table-row').forEach(row => {
        row.classList.add('row-white');
      });
    }, 200);
    setLoading(false);
    setOrders([]);
    const abortController = new AbortController();
    refreshOrders(abortController);

    return () => {
      abortController.abort("unmounted");
    };
  }, [tab, paginationNumber, rowsPerPageNumber]);

  const antIcon = (
    <LoadingOutlined
      className="custom-spin-icon"
      style={{ fontSize: "50px" }}
      spin
    />
  );
  const rowSelection = {
    // selectedRowKeys:[...parseInt(rowKeys)],
    onChange: (selectedRowKeys, selectedRows) => {
      setRowKeys(selectedRowKeys)
      setRows(selectedRows)
    },

  };
  // re authorize Ali Express notification 
  const [api, contextHolder] = notification.useNotification();


  // re authorize hanlder 
  const reAuthorizeAliExpress = async () => {
    // for ali express
    const appKey = '506940';
    const callbackUrl = URLS.BACKEND_API + URLS.ALIEXP_CALLBACK_URL;

    // message
    const { userId } = await currentAuthenticatedUser();
    const newUrl = `https://api-sg.aliexpress.com/oauth/authorize?response_type=code&force_auth=true&redirect_uri=${callbackUrl}&client_id=${appKey}&state=${userId}`;
    window.location.href = newUrl;
  }
  const openNotification = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <Space>

        <button
          onClick={() => api.destroy()}
          className={`replace-variant-btn  !bg-transparent !border-[--primary-color] !text-[--primary-color]
                disabled:cursor-not-allowed
                 disabled:text-[#f1edf9] 
                 disabled:bg-[#9e75ff] disabled:border-[#9e75ff]
                 h-[ 40px]
                 `}>
          Cancel</button>
        <button
          onClick={() => {
            reAuthorizeAliExpress()
            api.destroy();
          }}
          className={`replace-variant-btn 
                disabled:cursor-not-allowed
                 disabled:text-[#f1edf9] 
                 disabled:bg-[#9e75ff] disabled:border-[#9e75ff]
                 h-[ 40px]
                 `}>
          Authorize again
          <svg style={{ transform: "rotate(180deg)" }} width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.5 5L1.5 5M1.5 5L5 8.5M1.5 5L5 1.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </button>
      </Space>
    );
    api.error({
      message: 'AliExpress Authorization',
      description:
        'Please Authorize again!',
      btn,
      key,
      placement: 'top',
    });
  };
  const handleSearch = (value) => {

    if (wholeData.length === 0) {
      return
    }

    const e = value.trim()
    let filtered = []
    wholeData.forEach((record => {
      for (const key in record) {
        if (key === 'status' || key === 'order_number' || key === 'created_at'
          || key === 'destination' || key === 'total' || key === 'name' ||
          key === 'items_count') {
          if (key === 'status' || typeof (record[key]) === 'string' || typeof (record[key]) === 'number') {
            let val = key === 'status' ? record[key].status : key === 'created_at' ?
              new Date(record[key]).toLocaleDateString("en-GB") :
              typeof (record[key]) === 'number' ? (record[key]).toString() : record[key];
            if (val.toLowerCase().includes(e.toLowerCase())) {
              console.log(record[key], 'matching');
              filtered.push(record)
              return
            }

          }
        }
      }
    }
    ));
    if (filtered.length !== 0) {
      setOrders(filtered)
    }
  }


  const rowclassname = () => "table-row";
  return (
    <div className="orders-table-main-container">
      {contextHolder}
      {tab && (
        <Tabs defaultActiveKey="all" items={orderTabs} onChange={onTabChange} />
      )}
      <div className="filter-btn-row">
        <div className="  flex gap-2">
          <Dropdown
            menu={{
              items,
              onClick: async (menuItem) => {
                if (loading || !rows.length) {
                  return;
                }
                setLoading(true);


                try {
                  const response = await callBackendAPI(
                    "aliexpress/check-access-validity",
                    "GET",
                    {
                    }
                  );

                  if (!response?.status) {
                    setLoading(false);
                    openNotification()
                  } else {
                    doBulkAction(menuItem.key, rows).then((response) => {
                      const { data } = response
                      if (!data?.status) {
                        return message.error(data?.message, 3);
                      }
                      message.success(data?.message, 3)
                    }).
                      finally(() => {
                        refreshOrders()
                        setLoading(false);
                      }

                      );
                  }
                } catch (error) {
                  setLoading(false);
                  console.error("Error:", error);
                  message.error(`Api faied: ${error.message}`)
                }



              },
            }}
            placement="bottomLeft"
          >
            <div className="actions-btn">
              <img src={arrowDownIcon} alt="arrow down icon" />
              <span>Actions</span>
            </div>
          </Dropdown>
          <Dropdown
            menu={{
              items: filtersItems,
              onClick: (menuItem) => {
                setFilterBy(menuItem.key)
              },
            }}
            placement="bottomLeft"
          >
            <div className="actions-btn">
              <img src={filterIcon} alt="filter icon" />
              <span>{filterBy}</span>
            </div>
          </Dropdown>
          <button
            disabled={loading}

            className="import-product-btn !h-[42px] disabled:cursor-not-allowed"
            type="button"
            onClick={refreshOrders}
          >
            Refresh Orders
          </button>
        </div>
        <input
          className="search-input"
          placeholder="Search"
          onChange={(e) => handleSearch(e.target.value)}
        ></input>
      </div>

      <Spin indicator={antIcon} spinning={loading} style={{ width: "100%" }}>
        <div style={{ width: "100%" }}>
          <Table
            pagination={false}
            style={{ width: "100%" }}
            rowClassName={rowclassname}
            columns={columns}
            dataSource={orders}
            rowKey="id"

            rowSelection={{
              type: 'checkbox',
              ...rowSelection
            }}
          />
          {
            orderCounts[tab] > 0 ? (
              <TablePagination
                total={orderCounts[tab]}
                pageSize={rowsPerPageNumber}
                currentPage={paginationNumber}
                onPageChange={setPaginationNumber}
                onPageSizeChange={setRowsPerPageNumber}
              />
            ) : (
              ""
            )}
        </div>
      </Spin>

      <OrderFullfilmentSidePage refreshTable={refreshOrders}
        incorrectShipping={incorrectShipping} lineItems={lineItems}
        openDrawer={DrawerOpen} setDrawerOpen={setDrawerOpen} />
      {/* <Button onClick={() => {
        // setIncorrectShipping(true)
        // setDrawerOpen(true)
    dispatch(refreshOrdersTableController())

      }}>
        Open Missing Shipping Drawer
      </Button> */}
    </div>
  );
};

export default OrdersTable;
