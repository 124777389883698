import { Spin } from "antd";
import successIcon from "../../../assets/success-removebg-preview.png";
import ConnectStore from "../../ConnectStore";
import NotConnectedShopifyCard from "../../ConnectStore/NotConnectedShopifyCard";
import CustomLoader from "../../CustomLoader";

const TaskAnalysis = ({
  setOpenGuidance,
  shopifyData,
  aliExpressData,
  shopifyAuthCheck,
  aliexpressAuthCheck,
  isAliExpressAuthorize,
  shopifyLoading,
  aliExpressLoading,
  handleDisconnectAliExpress,
  disconnectAliExpressLoading, setDisconnectAliExpressLoading
}) => {
  return (
    <div className="flex w-full h-[70vh]  items-center">
      <div className="flex w-full">
        <div className="m-10 w-full">
          <label style={{ fontSize: "2.5rem", padding: "7%" }}>
            Connect Shopify
          </label>

          {shopifyAuthCheck ? (
            // <NotConnectedShopifyCard />

            <ConnectedCard
              component="shopify"
              shopifyLoading={shopifyLoading}
              aliExpressLoading={aliExpressLoading}
              data={shopifyData}
            />
          ) : (
            <NotConnectedShopifyCard />

            // to connect with shopify unn comment below component
            // <ConnectStore storeName={"shopify"} />
          )}
        </div>
        <div className="m-10 w-full">
          <label style={{ fontSize: "2.5rem", padding: "7%" }}>
            Connect AliExpress
          </label>
          {aliexpressAuthCheck ? (
            <ConnectedCard
              disconnectAliExpressLoading={disconnectAliExpressLoading} setDisconnectAliExpressLoading={setDisconnectAliExpressLoading}
              handleDisconnectAliExpress={handleDisconnectAliExpress}
              component="aliexpress"
              shopifyLoading={shopifyLoading}
              aliExpressLoading={aliExpressLoading}
              data={aliExpressData}
            // isAliExpressAuthorize={isAliExpressAuthorize}
            />
          ) : (
            <ConnectStore storeName={"aliexpress"} />
          )}
        </div>
      </div>
    </div>
  );
};

export default TaskAnalysis;

// card
const ConnectedCard = (prop) => {
  console.log("data of ali express and shopify", prop?.data);
  return (
    <Spin
      spinning={prop?.shopifyLoading && prop?.aliExpressLoading}
      tip="Loading..."
    >
      <div className="card mt-5 " style={{ minHeight: "20rem" }}>
        {prop?.shopifyLoading && prop?.aliExpressLoading ? (
          <></>
        ) : (
          <>
            <img
              className="w-[60px] h-[60px]"
              src={successIcon}
              alt="success"
            />
            <span className="text-[20px] font-semibold text-[#474747]">
              Connected!{" "}
            </span>
            {prop?.component === "shopify" ? (
              <>
                <p>You are connected with: </p>
                <h4 className="text-[18px] font-serif  font-semibold text-black">
                  <b>{prop?.data ? prop?.data : "Store"}</b>
                </h4>
              </>
            ) : (
              <>
                {/* <p>You are connected </p> */}
                <div className="W-[150px] h-[50px]">
                  <button
                    disabled={prop.disconnectAliExpressLoading}
                    type="submit"
                    onClick={prop.handleDisconnectAliExpress}
                    className=" continue-btn h-full flex justify-center items-center px-4 !bg-[#ba382f] hover:!bg-[#B42318] gap-1"
                  >
                    <span className="">{prop.disconnectAliExpressLoading ? "Disconnecting  " : "Disconnect"}</span>
                    {prop.disconnectAliExpressLoading && <CustomLoader width="20px" color="white" />}
                  </button>
                </div>
                {/* {
                  prop.isAliExpressAuthorize && (

                    <div className="card mt-5" style={{ minHeight: "20rem" }}>
                      <label className="text-[#474545] font-semibold">
                        You are currently not authorized with AliExpress.
                      </label>
                      <div className="w-[250px] h-[60px] border">
                        <button
                          //  onClick={handleAliExpressConnect}
                          className="continue-btn h-full flex justify-center items-center">
                          <span>Authorize again</span>
                        </button>
                      </div>
                    </div>

                  )
                } */}
              </>

            )}
          </>
        )}
      </div>
    </Spin>
  );
};
