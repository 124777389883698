import React, { useState, useEffect } from "react";
import DOMPurify from "dompurify";
import canvaIcon from "../../../assets/canva.svg";
const ProductFooter = ({
  text,
  heading,
  productFooterSection,
  handleSectionEditChange,
  section6Image,
  image,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [content, setcontent] = useState();
  useEffect(() => {
    setcontent({
      text: text,
      heading: heading,
    });
  }, [text, heading]);
  const sectionText = DOMPurify.sanitize(content?.text);
  const handleSectionImageClick = () => {
    handleSectionEditChange(["section6Image"]);
  };
  return (
    <div className="product-footer-main-continer">
      <div
        onClick={handleSectionImageClick}
        style={{ cursor: "pointer" }}
        className={`product-footer-img-container ${
          section6Image ? "outline-border-selected" : "outline-border"
        }`}
      >
        {image && (
          // <img
          //   className="product-footer-image"
          //   src={image}
          //   alt="Guarantee Image"
          // />
          <div
            onMouseEnter={() => {
              setIsHovered(true);
            }}
            onMouseLeave={() => {
              setIsHovered(false);
            }}
            style={{
              position: "relative",
              display: "inline-block",
              cursor: "pointer",
              marginBottom: 0,
              height: "250px",
            }}
          >
            <img
              style={{ cursor: "pointer" }}
              className="product-footer-image !mb-0"
              src={image}
              alt="Guarantee-image"
            />
            {isHovered && (
              <div
                className="background-gallery-image-overlay-edit-image-section  !rounded-none p-0"
                style={{
                  position: "absolute",
                }}
              >
                <div className="edit-image-upper-portion !items-center !justify-between"></div>
                <div className="edit-image-lower-portion">
                  <button
                    className="gallery-image-edit-button bg-white !w-auto !py-0 !pl-0 !pr-2 !h-[40px] !m-0 !justify-start hover:!bg-[#F4F6F7]"
                    onClick={handleSectionImageClick}
                  >
                    <img
                      className="m-[8px]"
                      width="125px"
                      src={canvaIcon}
                      alt="Sync Canva edits"
                    />
                    <p className="text-[13px] font-semibold">Change media</p>
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <div
        onClick={() =>
          handleSectionEditChange([
            "satisfactionGuaranteeHeadline",
            "satisfactionGuaranteeText",
          ])
        }
        style={{ cursor: "pointer" }}
        className={`product-footer-content-container ${
          productFooterSection ? "outline-border-selected" : "outline-border"
        }`}
      >
        {content?.heading ? <h3>{content?.heading}</h3> : ""}
        <div
          style={{ color: "black" }}
          className="edit-formatted-line"
          dangerouslySetInnerHTML={{ __html: sectionText }}
        ></div>
      </div>
    </div>
  );
};

export default ProductFooter;
