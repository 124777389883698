import { createSlice } from "@reduxjs/toolkit";




const publishProductSlice = createSlice({
    name: "publishProductSlice",

    initialState: {
        isPublish: false,
        currentEditingProductId: '',
        oldIdsOfMyProducts: [],
        publisPayload: {},
    },
    reducers: {
        setPublishTrue: (state, action) => {
            state.isPublish = true
            state.publisPayload = action.payload
        },
        setPublishFalse: (state, action) => {
            state.isPublish = false
        },
        publisProductId: (state, action) => {
            state.currentEditingProductId = action.payload
        },
        setOldIdsOfOfMyProducts: (state, action) => {
            state.oldIdsOfMyProducts = action.payload
        },

    }

});

export const {
    setPublishFalse,
    setPublishTrue,
    publisProductId,
    setOldIdsOfOfMyProducts,
} = publishProductSlice.actions;

export default publishProductSlice.reducer;

