import { createSlice } from "@reduxjs/toolkit";




const authUserSlice = createSlice({
    name: "authUserSlice",

    initialState: {
        shopName: undefined,
        shopOwner: undefined,
        email: undefined,
        shopifyShopDetails: {
            connected: false,
            name: ''
        }
    },
    reducers: {
        setStoreInformation: (state, action) => {
            const { shop_owner, email, shopifyConnectionDetails } = action.payload
            console.log(JSON.parse(JSON.stringify(shopifyConnectionDetails)));

            state.shopifyShopDetails = shopifyConnectionDetails ? shopifyConnectionDetails : state.shopifyShopDetails
            state.shopOwner = shop_owner === "undefined" ? undefined : shop_owner;
            state.email = email === "undefined" ? undefined : email
        },

    }

});

export const {
    setStoreInformation,

} = authUserSlice.actions;

export default authUserSlice.reducer;

