import React, { useEffect } from "react";
import "./MyProducts.css";
import Wrapper from "../Dashboard/Wrapper";
import ProductsStatusTable from "./ProductsStatusTable";
import CustomLoader from "../CustomLoader";
import { useDispatch, useSelector } from "react-redux";
import { setStoreInformation } from "../../redux/slices/auth-user-slice";
import currentAuthenticatedUser, { getCurrentAuthenticatedSession } from "../../authUser";
import { URLS } from "../../URLS";
import { message } from "antd";
import { useSearchParams } from "react-router-dom";
const MyProducts = () => {

  const dispatch = useDispatch()
  const { shopifyShopDetails } = useSelector(store => store.authUser)
  const { connected,
    name } = shopifyShopDetails||{}
    console.log('shopify details:',shopifyShopDetails );
   
  return <Wrapper>
    <div className="products-top-header">
      <h3>My Products</h3>
      {connected ? <h5>You are connected to <b>{name}</b></h5> : <h5>Shopify Store not connected! Please install Sofia App on your Shopify Store.</h5>}
      <ProductsStatusTable />
      <div className="bg-black">

      </div>
    </div>
  </Wrapper>;
};

export default MyProducts;
