import React, { useEffect, useState } from "react";
import duck1 from "../../../assets/duckimages/duck1.jpeg";
import duck2 from "../../../assets/duckimages/duck2.jpeg";
import duck3 from "../../../assets/duckimages/duck3.jpeg";
import duck4 from "../../../assets/duckimages/duck4.jpeg";
import duck5 from "../../../assets/duckimages/duck5.jpeg";
import duck6 from "../../../assets/duckimages/duck6.jpeg";
import duck7 from "../../../assets/duckimages/duck7.jpeg";
import chevronLeft from "../../../assets/chevron left.svg"
import chevronRight from "../../../assets/chevron right.svg"
import canvaIcon from '../../../assets/canva.svg'

import { FaCaretRight, FaCaretLeft } from "react-icons/fa";
import { useDrag, useDrop } from "react-dnd";
import "./ImageSection.css";

const ProductGallery = ({ images, handleSectionEditChange, galleryImages, setProductImages }) => {
  const [mainImage, setMainImage] = useState("");
  const [startIndex, setStartIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    setMainImage(images[0]);
  }, [images]);

  const handleNext = () => {
    if (startIndex + 5 < images.length) {
      setStartIndex(startIndex + 1);
    }
  };

  const handlePrev = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 1);
    }
  };
  const handleSectionImageClick = () => {
    handleSectionEditChange(["gallery"]);
  };



  return (
    <div

      className={`image-section-main-container  ${galleryImages ? "outline-border-selected" : "outline-border"}`}>
      {/* <div className="imge-seciton-image-container"> */}
      {images && (

        <div onMouseEnter={() => {
          setIsHovered(true);
        }}
          onMouseLeave={() => {
            setIsHovered(false);
          }}
          style={{ position: 'relative', display: 'inline-block', marginBottom: "20px", cursor: 'pointer' }}
          onClick={handleSectionImageClick}>
          <img
             style={{ cursor: "pointer" }}
            className="gallery-main-image !mb-0"
            src={mainImage}
            alt=""
          />
          {isHovered && (
            <div
              className="background-gallery-image-overlay-edit-image-section  !rounded-none"
              style={{
                position: 'absolute',
             
              }}
            >
              <div className="edit-image-upper-portion !items-center !justify-between"></div>
              <div className="edit-image-lower-portion">
                <button
                  className="gallery-image-edit-button bg-white !w-auto !py-0 !pl-0 !pr-2 !h-[40px] !m-0 !justify-start hover:!bg-[#F4F6F7]"
                  onClick={handleSectionImageClick}
                >
                  <img className="m-[8px]" width="125px" src={canvaIcon} alt="Sync Canva edits" />
                  <p className="text-[13px] font-semibold">Change media</p>
                </button>
              </div>
            </div>
          )}
        </div>
      )}

      {/* </div> */}

      {images && (
        <div className="gallery-thumbnails-container">
          <button
            onClick={handlePrev}
            disabled={startIndex === 0}
            className="arrow-button"
          >
            {/* <FaCaretLeft /> */}
            <img src={chevronLeft} alt="" />
          </button>
          <div className="gallery-thumbnails-wrapper">
            <div
              className="gallery-thumbnails"
              style={{ transform: `translateX(-${startIndex * 110}px)` }}
            >
              {images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`"image: ", ${index}`}
                  className="thumbnail"
                  onClick={() => setMainImage(image)}
                />
              ))}
              {/* {
                React.Children.toArray(
                  images.map((image, index) => (
                    <Card
                      setMainImage={setMainImage}
                      src={image}
                      title={`image:${index + 1}`}
                      id={index + 1}
                      index={index}
                      moveImage={moveImage}
                    />
                  ))
                )
              } */}
              {/* <ImagesReturn images={images} setProductImages={setProductImages} setMainImage={setMainImage} /> */}
            </div>
          </div>
          <button
            onClick={handleNext}
            disabled={startIndex + 5 >= images?.length}
            className="arrow-button"
          >
            <img src={chevronRight} alt="" />

            {/* <FaCaretRight /> */}
          </button>
        </div>
      )}
    </div>
  );
};

export default ProductGallery;

