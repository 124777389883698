import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import modaldownloadicon from "../../../assets/modaldownloadicon.svg";
import { callBackendAPI } from "../../../helpers/apiCalls";
import { useNavigate } from "react-router-dom";
import { setProductDataInState, resetProductDataInState, setEditViewStyle } from "../../../redux/slices/product-variants-slice";
import { useDispatch } from "react-redux";
import { setCountryMethod } from "../../../redux/slices/shipping-config-slice";
import CustomLoader from "../../CustomLoader";

const AddProductModal = ({ OpenModal, setOpModal, fetchProducts, handleOldIdsRecord }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const [importButtonLoading, setImportButtonLoading] = useState(false);
  const [productLink, setProductLink] = useState("");
  const [formwarning, setFormwarning] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    setProductLink("");
    setFormwarning("");

    if (document.getElementById('link-input')) {
      document.getElementById('link-input').focus()
    }
    const modal = document.querySelector('.ant-modal-content')
    if (modal) {
      modal.setAttribute('style', 'padding: 20px !important;');

    }
  }, [OpenModal]);


  const ConfirmButton = () => {
    return (
      <span>
        {!importButtonLoading ? (
          "Import product"
        ) : (
          <span className="flex justify-center items-center gap-2">
            <p>
              Importing product
            </p>
            <CustomLoader width='20px' />
          </span>
        )}
      </span>
    );
  };

  const handleCancel = () => {
    setOpModal(false);
    setSuccessMessage("");
    setProductLink("");
    setFormwarning("");
  };
  const handleConfirm = async () => {
    // setProductLink("https://www.aliexpress.com/item/1005006837393510.html?spm=a2g0o.productlist.main.1.57f[…]Uid=gR6YbWww1N4D&utparam-url=scene%3Asearch%7Cquery_from%3A")
    if (productLink) {
      setFormwarning("");
      setImportButtonLoading(true);

      //calling api

      try {
        // Make API call to backend
        const response = await callBackendAPI(
          "aliexpress/v1_fetch_product",
          "GET",
          { url: productLink } // Pass your request body here
        );

        if (!response?.error && response?.status) {
          handleOldIdsRecord();
          setProductLink("");
          setFormwarning("");

          const { data } = response
          const { productTemplate, product_variants, shippingConfiguration, variantsArrangedObject } = data
          const { variantDisplayStyle } = productTemplate



          dispatch(resetProductDataInState({}))
          console.log('payloads shippingConfiguration: ', shippingConfiguration);
          dispatch(setCountryMethod(shippingConfiguration ? shippingConfiguration : [
            { id: 0, country: '', method: [], issue: null }

          ]));
          dispatch(setProductDataInState(response ? { ...response, variantsArrangedObject: variantsArrangedObject ? variantsArrangedObject: {} } : {}))
          // edit view style
          if (variantDisplayStyle !== undefined && product_variants.length > 1) {
            const val = variantDisplayStyle === 'button' ? 'Pills' : 'Dropdown'
            dispatch(setEditViewStyle(val))
          }
          else {
            dispatch(setEditViewStyle('Dropdown'))
          }
          navigate(`/edit-products/${response?.product_id}`);
          // Close the modal after 2 seconds
          setTimeout(() => {
            fetchProducts();
            setSuccessMessage("");
            setOpModal(false);
          }, 1000);
        } else {
          setFormwarning(response.message);
        }
      } catch (error) {
        setFormwarning(error.message);
        console.error("Error:", error);
      } finally {
        setImportButtonLoading(false);
      }
    } else {
      setFormwarning("Please enter url of product");
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleConfirm();
    }
  };

  return (
    <div>
      <Modal
        closable={false}
        open={OpenModal}
        onOk={handleConfirm}
        onCancel={handleCancel}
        okText={<ConfirmButton />}
        cancelText="Cancel"
        okButtonProps={{
          className: importButtonLoading
            ? "modal-import-button modal-import-button-disabled"
            : "modal-import-button",
          disabled: importButtonLoading,
        }}
        cancelButtonProps={{
          className: importButtonLoading
            ? "product-modal-cancel-button-disabled"
            : "product-modal-cancel-button",
          disabled: importButtonLoading,
        }}
      >
        <div className="add-product-modal-main-container">
          <img src={modaldownloadicon} alt="modaldownloadicon" />
          <div className="add-prduct-content-container">
            <h4>Import product</h4>
            <p>Paste the link of the AliExpress product you want to import.</p>
          </div>
          <form
            style={{ gap: "0px" }}
            onSubmit={handleConfirm}
            className="input-form"
          >
            <span className="modal-input-label">AliExpress link</span>
            <input
              id="link-input"
              value={productLink}
              onChange={(e) => setProductLink(e.target.value)}
              style={{ marginTop: "8px" }}
              className="form-control"
              type="text"
              placeholder="https://aliexpress.com/my-product"
              onKeyDown={handleKeyPress}
            />
            {successMessage && (
              <span
                className="add-product-modal-success"
                style={{ color: "green" }}
              >
                {successMessage}
              </span>
            )}
            {formwarning ? (
              <span className="add-product-modal-warning">{formwarning}</span>
            ) : (
              <span style={{ height: "28px" }}></span>
            )}
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default AddProductModal;
