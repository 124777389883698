import { useEffect, useState } from "react"
import './ProductVariantsSection.css'
import { Select } from "antd"
import useVariantsData from "../../hooks/useVariantsData";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedVariantProperties, setVariantProperties } from "../../../redux/slices/product-variants-slice";
import checkDisableShipsFromOnEditView from "../../../Utilities/productVariantsHandlers/checkDisableShipsFromOnEditView";


const { Option } = Select;

const ProductVariantsSection = ({ productVariants, handleSectionEditChange,
  selectedProperty, setSelectedProperty
  , shipsFrom
}) => {
  const dispatch = useDispatch()
  const { productData, globallyDisabledProperties, editViewStyle, shipsFromArray,
    variantsArrangedIndexesObject: uniqueValuesObject,

  } = useSelector(store => store.productVariants)
  console.log('globallyDisabledProperties;',globallyDisabledProperties);
  
  const { data } = productData;
  const { product_variants } = data

  const { propertiesArray,
    variantsDetails,
    // uniqueValuesObject,
    formattedImagesObject,
    productimages,
    newImagesObject, disabling } = useVariantsData(productData)



  useEffect(() => {
    dispatch(setSelectedVariantProperties(selectedProperty))
  }, [selectedProperty])

  useEffect(() => {
    dispatch(setVariantProperties({
      propertiesArray,
      variantsDetails,
      uniqueValuesObject,
      formattedImagesObject,
      productimages,
      newImagesObject
    }))
  }, [])

  //  property selection is here
  const handleSelect = (property, e) => {
    dispatch(setSelectedVariantProperties({ ...selectedProperty, [property]: e }))

    setSelectedProperty({ ...selectedProperty, [property]: e })
  }

  useEffect(() => {
    const selectIcon = `
   <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="m4.45 7.43 4.375 4.375L13.2 7.43" stroke="#667085" stroke-width="1.458" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  
  `;

    document.querySelectorAll('.ant-select-suffix').forEach(svg => {
      svg.outerHTML = selectIcon;
    });
  }, [])

  return (
    <div onClick={() => handleSectionEditChange(['productVariants'])} className={`${productVariants ? "outline-border-selected" : "outline-border"} variants-main-container`}>
      {
        propertiesArray?.map((property, index) => {
          return (
            uniqueValuesObject[property]?.length > 1 ?

              <div key={index}>
                {/* <p className="color-size-text">{property} : {selectedProperty[property]} </p> */}
                {
                  editViewStyle === 'Dropdown' ?
                    <>
                      <Select
                        value={selectedProperty[property]}
                        className="pagination-form-control w-[100%] !h-[42px]"
                        onChange={(e) => handleSelect(property, e)}
                        style={{ width: '50%', marginBottom: '14px' }}
                        optionLabelProp="label"
                      >
                        {
                          uniqueValuesObject[property]?.map((col, i) => {

                            let disable
                            if (shipsFromArray.length > 0) {
                              disable = checkDisableShipsFromOnEditView({
                                product_variants,
                                property_name: property,
                                property_value: col,
                                globallyDisabledProperties,
                                shipsFrom: shipsFrom
                              })

                              // console.log('whole saler is ships array not 0', shipsFrom, property, col, '=>>>', disable);
                            }
                            else {

                              disable = globallyDisabledProperties[property]?.includes(col) ?? false;
                            }


                            return (


                              disable ? null : <Option disabled={disable} key={i} value={col}
                                label={
                                  <span className="flex gap-2 w-full items-center">
                                    {/* {
                                    newImagesObject.hasOwnProperty(property) && (<span
                                      style={{ backgroundImage: `url(${newImagesObject[property][col]})` }}
                                      className="variant-left-image-container  ">
                                    </span>)
                                  } */
                                    }

                                    <p>{col}</p>
                                  </span>}  >

                                <span className="flex gap-2 w-full items-center">
                                  {/* {
                                  newImagesObject.hasOwnProperty(property) &&
                                  (<span
                                    style={{ backgroundImage: `url(${newImagesObject[property][col]})` }}
                                    className="variant-left-image-container  ">

                                  </span>)
                                } */}
                                  <p>{col}</p>
                                </span>
                              </Option>
                            )
                          }
                          )
                        }
                      </Select>
                    </>
                    :
                    <div className="flex gap-2 flex-wrap items-center !mb-[14px]">
                      {uniqueValuesObject[property]?.map((col, i) => {


                        let disable
                        if (shipsFromArray.length > 0) {
                          disable = checkDisableShipsFromOnEditView({
                            product_variants,
                            property_name: property,
                            property_value: col,
                            globallyDisabledProperties,
                            shipsFrom: shipsFrom
                          })

                          // console.log('whole saler is ships array not 0', shipsFrom, property, col, '=>>>', disable);
                        }
                        else {

                          disable = globallyDisabledProperties[property]?.includes(col) ?? false;
                        }


                        return (
                          newImagesObject.hasOwnProperty(property) ?
                            disable ? null : <span key={i} onClick={(e) => disable ? null : handleSelect(property, col)}
                              style={{
                                //  backgroundImage: `url(${newImagesObject[property][col]})` 
                              }}
                              className={`variant-left-image-container ${disable && '!cursor-not-allowed !border-[#a5afbb]'}  ${col === selectedProperty[property] && '!bg-[#f4f6f7] !border-[black]'}`}>
                              <img className="sku-img-styles !h-[28px]" src={newImagesObject[property][col] || ''} alt="" />

                            </span> :
                            disable ? null : <span key={i} onClick={(e) => disable ? null : handleSelect(property, col)} className={`left-variant-pills-content-container ${disable && '!cursor-not-allowed !border-[#a5afbb] '}  ${col === selectedProperty[property] && '!bg-[#f4f6f7] !border-[black]'} `}>
                              {col}
                            </span>
                        )
                      })

                      }
                    </div>
                }
              </div>
              : null
          )
        })
      }
    </div>
  )
}
export default ProductVariantsSection
