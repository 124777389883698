import { createSlice } from "@reduxjs/toolkit";
import checkFilteredEnabledVariants from "../../Utilities/checkFilteredEnabledVariants";




const productVariantsSlice = createSlice({
    name: "productVariantsSlice",

    initialState: {

        fillteredProductData: {},
        productData: null,
        productBulletBenefits: undefined,
        matchedVariant: {},
        propertiesArray: {},
        variantsDetails: {},
        uniqueValuesObject: {},
        globallyDisabledProperties: {},
        mediaImages: [],
        changeVariantImageKey: undefined,
        newImagesObject: {},
        startSelectionObject: {},
        selectedProperties: {},
        toUpdateImageDetails: { index: null, to: '', key: '', imageUrl: '' },
        variantsArrangedIndexesObject: {

        },
        editViewStyle: '',
        disabling: true,
        enableDisable: false,
        focusedInput: null,
        propertiesLength: 0,
        totalEnabledVariants: null,
        totalVriants: null,
        totalAllowedVariants: 100,
        loading: false,
        success: false,
    },

    reducers: {
        setEditViewStyle: (state, action) => {
            const val = action.payload
            state.editViewStyle = val
        },
        resetProductDataInState: (state, action) => {
            // state.productData = 
            const initialProductState = {
                fillteredProductData: {},
                productData: null,
                productBulletBenefits: undefined,
                matchedVariant: {},
                propertiesArray: {},
                variantsDetails: {},
                uniqueValuesObject: {},
                globallyDisabledProperties: {},
                mediaImages: [],
                shipsFromArray: [],
                changeVariantImageKey: undefined,
                newImagesObject: {},
                startSelectionObject: {},
                selectedProperties: {},
                disabling: true,
                focusedInput: null,
                propertiesLength: 0,
                totalEnabledVariants: null,
                totalVriants: null,
                totalAllowedVariants: 100,
                loading: false,
                success: false,
                shipsFrom: [],
                specificShipsFromVariants: { shipsFrom: 'Select Ship from', total: 0 }
                ,
                variantsArrangedIndexesObject: {

                },
            };
            return initialProductState;
        },

        setProductDataInState: (state, action) => {
            // state.productData = 
            // state.variantsArrangedIndexesObject = {

            // };

            state.productBulletBenefits = action.payload.data.productTemplate.bulletBenefits;
            const { data ,variantsArrangedObject} = action.payload
            const { product_variants } = data
            state.totalVriants = product_variants.length
            // arrange variants 
            let aliexpressSkuObject = {};
            state.variantsArrangedIndexesObject = variantsArrangedObject || {}

            product_variants.forEach((variant, index) => {
                variant.variant_properties.forEach(property => {
                    if (!aliexpressSkuObject[property.shopify_property_value]) {
                        aliexpressSkuObject[property.shopify_property_value] = []
                    }
                })
            })
            product_variants.forEach((variant, index) => {
                variant.variant_properties.forEach((property, index) => {
                    if (variant.variant_properties.length > index + 1) {

                        if (aliexpressSkuObject[property.shopify_property_value].length === 0) {

                            aliexpressSkuObject[property.shopify_property_value] = [variant]
                        } else {

                            const arr = aliexpressSkuObject[property.shopify_property_value]
                            aliexpressSkuObject[property.shopify_property_value] = [...arr, variant]

                        }
                    }

                })
            })
            const arranged = Object.values(aliexpressSkuObject)
            console.log('hhhhdsjdw9eyfhsdbcuhsdgcusd', arranged, product_variants.length);
            //******/ calculate actual variants skipping the ships from ********
            const shipsTo = product_variants.flatMap(variant =>
                variant.variant_properties.filter(property => property.shopify_property_label === 'Ships From').map(property => (property.shopify_property_value))
            );
            const shipsFromArray = [...new Set(shipsTo)]
            state.shipsFromArray = shipsFromArray;

            const filteredVariants = checkFilteredEnabledVariants({ product_variants, shipsFrom: action?.payload?.shipsFrom, shipsFromLength: shipsFromArray.length })

            state.totalEnabledVariants = filteredVariants.filter(item => item.is_disabled === false).length;
            console.log(' ************enabled variants ******');
            console.log('Enabled Length:', filteredVariants.filter(item => item.is_disabled === false).length);
            console.log('Enabled Length:', action.payload);
            console.log(' ************enabled variants ******');
            const propertiesCount = [];
            let uniqueProperties = {};
            let isGloballyDisbled = [];

            product_variants.forEach(variant => {
                variant.variant_properties.forEach(prop => {
                    if (!uniqueProperties[prop.shopify_property_label]?.includes(prop.shopify_property_value) ?? false) {
                        uniqueProperties = {
                            ...uniqueProperties,
                            [prop.shopify_property_label]: [...(uniqueProperties[prop.shopify_property_label] || []), prop.shopify_property_value]
                        };
                    }
                });
            });


            product_variants.forEach(variant => {
                variant.variant_properties.forEach(prop => {
                    Object.entries(uniqueProperties).forEach(([property, values]) => {
                        values.forEach(value => {
                            if (prop.shopify_property_label === property && value === prop.shopify_property_value) {
                                propertiesCount.push({ [property]: value });
                                if (variant.is_disabled) {
                                    isGloballyDisbled.push({ [property]: value, isDisabled: variant.is_disabled });
                                }
                            }
                        });
                    });
                });
            });



            const countOccurrences = (array) => {
                const counts = {};
                array.forEach(item => {
                    Object.keys(item).forEach(key => {
                        if (key !== 'isDisabled') {
                            if (!counts[key]) {
                                counts[key] = {};
                            }
                            const value = item[key];
                            counts[key][value] = (counts[key][value] || 0) + 1;
                        }
                    });
                });
                return counts;
            };

            // Function to check occurrences and perform functionality
            const checkOccurrences = (counts) => {
                Object.entries(counts).forEach(([property, values]) => {
                    Object.entries(values).forEach(([value, count]) => {
                        if (count === propertiesCounts[property][value]) {
                            console.log(` %c The ${property} "${value}" appears ${count} times DISABLED & ${propertiesCounts[property][value]} is ENABLED now it is globally disabled.`, 'color: red; font-weight:bold');
                            if (!state.globallyDisabledProperties[property]?.includes(value) ?? false) {
                                state.globallyDisabledProperties = {
                                    ...state.globallyDisabledProperties,
                                    [property]: [...(state.globallyDisabledProperties[property] || []), value]
                                };
                            }

                        } else {
                            console.log(` %c The ${property} "${value}" appears ${count} times  DISABLED & ${propertiesCounts[property][value]} is ENABLED now it is not globally disabled.`, 'color: yellow; font-weight:bold');
                            const updatedGlobalProperties = { ...state.globallyDisabledProperties };
                            if (updatedGlobalProperties[property]) {
                                updatedGlobalProperties[property] = updatedGlobalProperties[property].filter(v => v !== value);
                                if (updatedGlobalProperties[property].length === 0) {
                                    delete updatedGlobalProperties[property];
                                }
                            }
                            state.globallyDisabledProperties = updatedGlobalProperties;
                        }
                    });
                });
            };

            const counts = countOccurrences(isGloballyDisbled);
            const propertiesCounts = countOccurrences(propertiesCount);
            checkOccurrences(counts);


            // const updated = product_variants.map((item) => {
            //     return {
            //         ...item,
            //         ['enableSalePrice']: false,
            //     };
            // });
            state.productData = {
                ...action.payload,
                data: {
                    ...data,
                    product_variants: product_variants,
                },
            };
        },
        changeProductBullets: (state, action) => {
            state.productBulletBenefits = action.payload;
        },

        changeSelectedVariantImage: (state, action) => {
            const newImage = action.payload;
            const { data } = state.productData;
            const { product_variants } = data;

            const updatedPropertiesArray = product_variants.map((item) => {
                return {
                    ...item,
                    variant_properties: item.variant_properties.map((prop) => {
                        if (prop.shopify_property_value === state?.changeVariantImageKey) {
                            return {
                                ...prop,
                                image: newImage,
                            };
                        }
                        return prop;
                    }),
                };
            });

            // Assign the new array to the state
            state.productData = {
                ...state.productData,
                data: {
                    ...data,
                    product_variants: updatedPropertiesArray,
                },
            };
            state.changeVariantImageKey = undefined;
        },
        setChangeVariantImageKey: (state, action) => {
            state.changeVariantImageKey = action?.payload;
            console.log(action?.payload);

        },

        setMediaImages: (state, action) => {
            const updatedImages = [...state.mediaImages, ...action.payload];
            state.mediaImages = updatedImages;
        },

        setMatchingVariant: (state, action) => {
            state.matchedVariant = action.payload
        },
        editRegularPrice: (state, action) => {
            state.matchedVariant = action.payload
        },
        editSalePrice: (state, action) => {
            const updated = { ...state.matchedVariant }
            updated.sku_sale_price = 5

            state.matchedVariant
                = updated
        },

        setVariantProperties: (state, action) => {

            const { propertiesArray,
                variantsDetails,
                uniqueValuesObject,
                productimages,
                newImagesObject } = action.payload


            state.propertiesArray = propertiesArray;
            state.variantsDetails = variantsDetails;
            state.uniqueValuesObject = uniqueValuesObject;
            state.newImagesObject = newImagesObject;

            // state.startSelectionObject = startSelectionObject;
        },

        setSelectedVariantProperties: (state, action) => {
            state.selectedProperties = action.payload
        },

        setPriceOfAllVariants: (state, action) => {
            const { to, value } = action.payload
            const { data } = state.productData;
            const { product_variants } = data;
            let updatedPropertiesArray;
            console.log('vvvvvvvvvvvvvv', value);
            if (to === 'price') {
                updatedPropertiesArray = product_variants.map(item => {
                    return {
                        ...item,
                        price: parseFloat(value).toFixed(2),
                    };
                });

            } else if (to === 'salePrice') {
                updatedPropertiesArray = product_variants.map(item => {
                    return {
                        ...item,
                        discounted_price: parseFloat(value).toFixed(2),
                    };
                });
            } else {
                updatedPropertiesArray = product_variants.map(item => {
                    return {
                        ...item,
                        enableSalePrice: value,
                    };
                });
            }


            // Assign the new array to the state
            state.productData = {
                ...state.productData,
                data: {
                    ...data,
                    product_variants: updatedPropertiesArray
                }
            };

        },

        changeLabelNameOfProperties: (state, action) => {
            const { value, property } = action.payload;
            const { data } = state.productData;
            const { product_variants } = data;
        
            // Allow the value to be empty at the frontend, but never clear it entirely in state
            const newValue = value.trim() === "" ? state.productData.data.product_variants[0].variant_properties.find(prop => prop.shopify_property_label === property)?.shopify_property_label || property : value;
        
            // Handle globally disabled properties update if needed
            if (state.globallyDisabledProperties[property]?.length > 0) {
                const oldData = [...state.globallyDisabledProperties[property]];
                const globalVariables = { [newValue]: oldData };
                state.globallyDisabledProperties = { ...state.globallyDisabledProperties, ...globalVariables };
                delete state.globallyDisabledProperties[property];
            }
        
            // Update product variants with the new label (newValue instead of empty)
            const updatedPropertiesArray = product_variants.map(item => {
                return {
                    ...item,
                    variant_properties: item.variant_properties.map(prop => {
                        if (prop.shopify_property_label === property) {
                            return {
                                ...prop,
                                shopify_property_label: newValue // Use the new value (not empty)
                            };
                        }
                        return prop;
                    })
                };
            });
        
            // Assign the new array to the state
            state.productData = {
                ...state.productData,
                data: {
                    ...data,
                    product_variants: updatedPropertiesArray
                }
            };
        
            // Maintain another array for drag and drop and viewing the data
            function renameSpecificKeys(obj, keysToRename) {
                return Object.keys(obj).reduce((acc, key) => {
                    const newKey = keysToRename[key] || key;
                    acc[newKey] = obj[key];
                    return acc;
                }, {});
            }
        
            const upadtedPropertyLabels = renameSpecificKeys(state.variantsArrangedIndexesObject, { [property]: newValue });
            console.log("value, property, updatedValue ", JSON.stringify(upadtedPropertyLabels));
            
            state.variantsArrangedIndexesObject = upadtedPropertyLabels;
            state.uniqueValuesObject = upadtedPropertyLabels;
        }
        ,
        



        changePriceAndDiscountOfVariants: (state, action) => {
            const { propertyValue, property, value: initialValue, toChange, shipsFrom } = action.payload
            const value = initialValue.replace(/^0+(?!$)/, '');

            if (value < 0 || value > 10000) {
                return
            }
            const { data } = state.productData;
            const { product_variants } = data;

            // console.log('change price');
            let variants = [];


            // Find the variant that matches the given property and value
            product_variants.map(item => {
                item.variant_properties.map(prop => {
                    if (prop.shopify_property_value === propertyValue
                    ) {
                        variants.push(item)
                    }
                });
            });
            console.log(':value to be price change::====', JSON.parse(JSON.stringify(variants)));

            if (variants.length < 1) {
                return;
            }

            if (toChange === 'price') {
                const updatedVariants = product_variants.map(item => {
                    const matched = variants.find(v => v.aliexpress_sku === item.aliexpress_sku)
                    if (matched) {
                        return {
                            ...item,
                            price: value,
                        };
                    }
                    return item;
                });

                state.productData = {
                    ...state.productData,
                    data: {
                        ...data,
                        product_variants: updatedVariants
                    }
                };

            } else {
                const updatedVariants = product_variants.map(item => {
                    const matched = variants.find(v => v.aliexpress_sku === item.aliexpress_sku)

                    if (matched) {
                        return {
                            ...item,
                            discounted_price: value,
                        };
                    }
                    return item;
                });

                state.productData = {
                    ...state.productData,
                    data: {
                        ...data,
                        product_variants: updatedVariants
                    }
                };
            }

        },
        enableDiscountOfVariants: (state, action) => {
            const { propertyValue, properties, onOff, parentChild, shipsFrom } = action.payload
            const { data } = state.productData;
            const { product_variants } = data;

            console.log('change switch:', onOff, propertyValue);
            let variants = [];
            let matchedProp = []

            if (parentChild) {


                // Find the variant that matches the given property and value
                product_variants.map(item => {
                    item.variant_properties.map(prop => {
                        if (prop.shopify_property_value === propertyValue
                        ) {
                            variants.push(item)
                        }
                    });
                });

                const updatedVariants = product_variants.map(item => {
                    const matched = variants.find(v => v.aliexpress_sku === item.aliexpress_sku)

                    if (matched) {
                        return {
                            ...item,
                            enableSalePrice: !onOff,
                            // discounted_price: value,
                        };
                    }
                    return item;
                });

                state.productData = {
                    ...state.productData,
                    data: {
                        ...data,
                        product_variants: updatedVariants
                    }
                };

            } else {
                const updated = product_variants.map(variant => {
                    variant.variant_properties.map((prop, i) => {
                        if (properties[prop.shopify_property_label] === prop.shopify_property_value
                            || (prop.shopify_property_label === 'Ships From' && prop.shopify_property_value === shipsFrom)
                        ) {
                            matchedProp.push(true)

                        }
                    })

                    if (matchedProp.length === variant.variant_properties.length) {
                        // disable = variant.is_disabled
                        matchedProp = []

                        return {
                            ...variant,
                            enableSalePrice: !onOff,

                        }
                    }
                    matchedProp = []
                    return variant
                })

                state.productData = {
                    ...state.productData,
                    data: {
                        ...data,
                        product_variants: updated
                    }
                };

            }



        },
        enableDiscountOfSpecific: (state, action) => {

        },
        changePriceAndDiscountOfSpecific: (state, action) => {
            const { toChange, properties, value, shipsFrom } = action.payload
            if (value < 0) {
                return
            }
            let matchedProp = []
            const { data } = state.productData;
            const { product_variants } = data;


            if (toChange === 'price') {
                const updated = product_variants.map(variant => {
                    variant.variant_properties.map((prop, i) => {
                        if (properties[prop.shopify_property_label] === prop.shopify_property_value ||
                            (prop.shopify_property_label === 'Ships From' && prop.shopify_property_value === shipsFrom)) {
                            matchedProp.push(true)

                        }
                    })

                    if (matchedProp.length === variant.variant_properties.length) {

                        // disable = variant.is_disabled
                        matchedProp = []

                        return { ...variant, price: value }
                    }
                    matchedProp = []
                    return variant
                })

                state.productData = {
                    ...state.productData,
                    data: {
                        ...data,
                        product_variants: updated
                    }
                };
            } else {
                const updated = product_variants.map(variant => {
                    variant.variant_properties.map((prop, i) => {
                        if (properties[prop.shopify_property_label] === prop.shopify_property_value || (prop.shopify_property_label === 'Ships From' && prop.shopify_property_value === shipsFrom)) {
                            matchedProp.push(true)

                        }
                    })

                    if (matchedProp.length === variant.variant_properties.length) {

                        // disable = variant.is_disabled
                        matchedProp = []

                        return { ...variant, discounted_price: value }
                    }
                    matchedProp = []
                    return variant
                })

                state.productData = {
                    ...state.productData,
                    data: {
                        ...data,
                        product_variants: updated
                    }
                };


            }




        },
        disableVariant: (state, action) => {
            const { property, value, is_disabled, shipsFrom } = action.payload;
            // to update the selected properties at product template 
            console.log('=-=-=-=-=', property, value, is_disabled, 'state.uniqueValuesObject', JSON.stringify(state.uniqueValuesObject));

            state.enableDisable = !state.enableDisable

            if (is_disabled) {
                console.log('=-=-=-=-=if disable true',);

                if (!state.globallyDisabledProperties[property]?.includes(value) ?? false) {
                    console.log('=-=-=-=-=if disable true true',);

                    state.globallyDisabledProperties = {
                        ...state.globallyDisabledProperties,
                        [property]: [...(state.globallyDisabledProperties[property] || []), value]
                    };
                }
            } else {
                console.log('=-=-=-=-=if disable false',);

                const updatedProperties = { ...state.globallyDisabledProperties };
                if (updatedProperties[property]) {
                    updatedProperties[property] = updatedProperties[property].filter(v => v !== value);
                    if (updatedProperties[property].length === 0) {
                        delete updatedProperties[property];
                    }
                }
                state.globallyDisabledProperties = updatedProperties;
            }



            const isAllDisabled = state.uniqueValuesObject[property]?.length <= state.globallyDisabledProperties[property]?.length;

            // console.log("================= variants info =====================")
            // console.log(JSON.parse(JSON.stringify(state.globallyDisabledProperties)));
            // console.log("all disabled ",isAllDisabled);
            // console.log("================= variants info =====================")

            if (isAllDisabled) {

                const updatedProperties = { ...state.globallyDisabledProperties };
                if (updatedProperties[property]) {
                    updatedProperties[property] = updatedProperties[property].filter(v => v !== value);
                    if (updatedProperties[property].length === 0) {
                        delete updatedProperties[property];
                    }
                }
                state.globallyDisabledProperties = updatedProperties;

            } else {

                const { data } = state.productData;
                const { product_variants } = data;

                const preFilteredVariants = checkFilteredEnabledVariants({ product_variants, shipsFrom: state.productData.shipsFrom, shipsFromLength: state.shipsFromArray.length })
                console.log('filtered variants from ships from =>', preFilteredVariants.length, 'disabled:', preFilteredVariants.filter(item => item.is_disabled === false).length);


                state.disabling = !state.disabling
                // state.totalEnabledVariants = product_variants.filter(item => item.is_disabled === false).length;
                // state.totalVriants = product_variants.length
                state.totalEnabledVariants = preFilteredVariants.filter(item => item.is_disabled === false).length;
                state.totalVriants = product_variants.length

                let variant = [];

                // Find the variant that matches the given property and value
                product_variants.map(item => {
                    item.variant_properties.map(prop => {
                        if (prop.shopify_property_value === value) {
                            variant.push(item)
                        }
                    });
                });
                // console.log(':value to be disabled', JSON.parse(JSON.stringify(variant)));

                if (variant.length < 1) {
                    return;
                }

                let disabled = []
                // Create a new array of product_variants with the updated is_disabled property
                const updatedVariants = product_variants.map(item => {
                    const matched = variant.find(v => v.aliexpress_sku === item.aliexpress_sku)

                    if (matched) {

                        const isDisabled = matched.variant_properties.some(property_name =>
                            state.globallyDisabledProperties[property_name.shopify_property_label]?.includes(property_name.shopify_property_value)
                        );

                        return {
                            ...item,
                            is_disabled: isDisabled ? true : is_disabled
                        };

                    }
                    return item;
                });


                // Update the state with the modified product variants
                state.productData = {
                    ...state.productData,
                    data: {
                        ...data,
                        product_variants: updatedVariants
                    }
                };

                const updatedFilteredVariants = checkFilteredEnabledVariants({ product_variants: updatedVariants, shipsFrom: state.productData.shipsFrom, shipsFromLength: state.shipsFromArray.length })
                console.log('filtered variants from ships from =>', updatedFilteredVariants.length, 'disabled:', updatedFilteredVariants.filter(item => item.is_disabled === false).length);

                state.totalEnabledVariants = updatedFilteredVariants.filter(item => item.is_disabled === false).length;
                state.totalVriants = product_variants.length

                console.log(" %c ----------------    PRODUCT VARIANTS ----------------", 'color: blue; font-weight:bold');
                updatedVariants.forEach(item => {

                    console.log("%c ########  VARIANT   #########", 'color: green; font-weight:bold');
                    item.variant_properties.forEach(prop => {
                        console.log(`%c VARIANT => LABEL => ${prop.shopify_property_label}           VALUE => ${prop.shopify_property_value}                        IS_DISABLES => ${item.is_disabled} \n`, `color: ${item.is_disabled ? "red" : "green"}; font-weight:bold`)
                    });
                    console.log("%c ########  VARIANT   ######### \n", 'color: green; font-weight:bold');
                });
                console.log(" %c ---------------- PRODUCT VARIANTS ----------------", 'color: blue; font-weight:bold');
                // 
            }

        },
        disableSpecificProperties: (state, action) => {
            const { properties, isDisabled, shipsFrom } = action.payload
            console.log(shipsFrom, ':::in redux ships from');

            const { data } = state.productData;
            const { product_variants } = data;

            if (product_variants.filter(item => item.is_disabled === false).length <= 1 && isDisabled == true) {
                return;
            }

            let matchedProp = [];

            let getVariant = {};

            let isGloballyDisbled = [];

            // Find the variant that matches the given property and value
            product_variants.forEach(variant => {

                variant.variant_properties.forEach(prop => {

                    if (properties[prop.shopify_property_label] === prop.shopify_property_value || (prop.shopify_property_label === 'Ships From' && prop.shopify_property_value === shipsFrom)) {
                        matchedProp.push(true)
                    }
                });
                // const lengthToCheckOfProperties = shipsFrom ? variant.variant_properties.length - 1 : variant.variant_properties.length
                if (matchedProp.length === variant.variant_properties.length) {

                    getVariant = variant
                    matchedProp = []
                    return
                }
                matchedProp = []
            });
            // let disabled = []
            // Create a new array of product_variants with the updated is_disabled property
            const updatedVariants = product_variants.map(item => {
                // const matched = variant.find(v => v.aliexpress_sku === item.aliexpress_sku)
                if (item.aliexpress_sku === getVariant.aliexpress_sku) {
                    // disabled.push(getVariant.variant_properties)

                    return {
                        ...item,
                        is_disabled: isDisabled
                    };
                }
                return item;
            });
            const updatedFilteredVariants = checkFilteredEnabledVariants({ product_variants: updatedVariants, shipsFrom: shipsFrom, shipsFromLength: state.shipsFromArray.length })
            console.log('filtered variants from ships from =>', updatedFilteredVariants.length, 'disabled:', updatedFilteredVariants.filter(item => item.is_disabled === false).length);

            state.totalEnabledVariants = updatedFilteredVariants.filter(item => item.is_disabled === false).length;
            state.totalVriants = product_variants.length

            const propertiesCount = [];
            updatedVariants.forEach(variant => {
                variant.variant_properties.forEach(prop => {
                    Object.entries(state.uniqueValuesObject).forEach(([property, values]) => {
                        values.forEach(value => {
                            if (prop.shopify_property_label === property && value === prop.shopify_property_value) {
                                propertiesCount.push({ [property]: value });
                                if (variant.is_disabled) {
                                    isGloballyDisbled.push({ [property]: value, isDisabled: variant.is_disabled });
                                }
                            }
                        });
                    });
                });
            });



            const countOccurrences = (array) => {
                const counts = {};
                array.forEach(item => {
                    Object.keys(item).forEach(key => {
                        if (key !== 'isDisabled') {
                            if (!counts[key]) {
                                counts[key] = {};
                            }
                            const value = item[key];
                            counts[key][value] = (counts[key][value] || 0) + 1;
                        }
                    });
                });
                return counts;
            };

            // Function to check occurrences and perform functionality
            const checkOccurrences = (counts) => {
                Object.entries(counts).forEach(([property, values]) => {
                    Object.entries(values).forEach(([value, count]) => {
                        if (count === propertiesCounts[property][value]) {
                            console.log(` %c The ${property} "${value}" appears ${count} times DISABLED & ${propertiesCounts[property][value] - count} is ENABLED now it is globally disabled.`, 'color: red; font-weight:bold');
                            if (!state.globallyDisabledProperties[property]?.includes(value) ?? false) {
                                state.globallyDisabledProperties = {
                                    ...state.globallyDisabledProperties,
                                    [property]: [...(state.globallyDisabledProperties[property] || []), value]
                                };
                            }

                        } else {
                            console.log(` %c The ${property} "${value}" appears ${count} times  DISABLED & ${propertiesCounts[property][value] - count} is ENABLED now it is not globally disabled.`, 'color: yellow; font-weight:bold');
                            const updatedGlobalProperties = { ...state.globallyDisabledProperties };
                            if (updatedGlobalProperties[property]) {
                                updatedGlobalProperties[property] = updatedGlobalProperties[property].filter(v => v !== value);
                                if (updatedGlobalProperties[property].length === 0) {
                                    delete updatedGlobalProperties[property];
                                }
                            }
                            state.globallyDisabledProperties = updatedGlobalProperties;
                        }
                    });
                });
            };

            const counts = countOccurrences(isGloballyDisbled);
            const propertiesCounts = countOccurrences(propertiesCount);

            checkOccurrences(counts);
            // console.log("================= variants info =====================")
            // console.log(JSON.parse(JSON.stringify(state.globallyDisabledProperties)));
            // console.log("================= variants info =====================")


            // Update the state with the modified product variants
            state.productData = {
                ...state.productData,
                data: {
                    ...data,
                    product_variants: updatedVariants
                }
            };
        },


        disablePropertyOfVariant: (state, action) => {
            const { property, value } = action.payload;
            const { data } = state.productData;
            const { product_variants } = data;

            let variant = null;

            // Find the variant that matches the given property and value
            product_variants.forEach(item => {
                item.variant_properties.forEach(prop => {
                    if (prop.shopify_property_label === property && prop.shopify_property_value === value) {
                        variant = item;
                    }
                });
            });

            if (!variant) {
                return;
            }

            // Create a new array of product_variants with the updated is_disabled property
            const updatedVariants = product_variants.map(item => {
                if (item.aliexpress_sku === variant.aliexpress_sku) {
                    return {
                        ...item,
                        is_disabled: !item.is_disabled
                    };
                }
                return item;
            });

            state.productData = {
                ...state.productData,
                data: {
                    ...data,
                    product_variants: updatedVariants
                }
            };

        },
        changeLabelOfProperties: (state, action) => {
            const { value, property, propertyValue } = action.payload;
            console.log("value, property, propertyValue ", value, property, propertyValue);
            
            const find = state.variantsArrangedIndexesObject[property].find((val) => val === value)
            if(find) return 
            // maintaining another array for drag and drop and viewing the data
            const propertyArray = state.variantsArrangedIndexesObject[property];
            const index = propertyArray.findIndex((val) => val === propertyValue)
            propertyArray[index] = value
            state.variantsArrangedIndexesObject = {
                ...state.variantsArrangedIndexesObject,
                property: propertyArray
            }
            state.uniqueValuesObject = {
                ...state.uniqueValuesObject,
                property: propertyArray
            }
            // *******

            const { data } = state.productData;
            const { product_variants } = data;

            const updatedPropertiesArray = product_variants.map(item => {
                return {
                    ...item,
                    variant_properties: item.variant_properties.map(prop => {
                        if (prop.shopify_property_label === property && prop.shopify_property_value === propertyValue) {
                            return {
                                ...prop,
                                shopify_property_value: value
                            };
                        }
                        return prop;
                    })
                };
            });


            // Assign the new array to the state
            state.productData = {
                ...state.productData,
                data: {
                    ...data,
                    product_variants: updatedPropertiesArray
                }
            };


        }
        ,
        changeSKU: (state, action) => {

            const { properties, value, id, shipsFrom, } = action.payload;

            state.focusedInput = id
            const { data } = state.productData;
            const { product_variants } = data;

            const updatedVariants = product_variants.map(variant => {
                const isMatched = variant.variant_properties.every(prop =>
                    properties[prop.shopify_property_label] === prop.shopify_property_value || (prop.shopify_property_label === 'Ships From' && prop.shopify_property_value === shipsFrom)
                );
                
                if (isMatched) {
                    return { ...variant, shopify_sku:  value };
                }

                return variant;
            });

            state.productData.data.product_variants = updatedVariants;
        },


        recalculateTheEnabledVariants: (state, action) => {
            const { shipsFrom } = action.payload
            if (shipsFrom) {

                const { data } = state.productData
                const { product_variants } = data
                const filteredVariants = checkFilteredEnabledVariants({ product_variants, shipsFrom: shipsFrom, shipsFromLength: state.shipsFromArray.length })
                console.log('filtered variants from ships from =>', filteredVariants.length, 'disabled:', filteredVariants.filter(item => item.is_disabled === false).length);
                state.totalEnabledVariants = filteredVariants.filter(item => item.is_disabled === false).length
                state.totalVriants = product_variants.length

                state.specificShipsFromVariants = { shipsFrom: shipsFrom, total: filteredVariants.length }
            }
        },
        disableSpecificShipsfromOnFirstStep: (state, action) => {
            const {
                property_name,
                property_value,
                shipsFrom, is_disabled } = action.payload
            console.log('=-=-=-=-=-=-', property_name,
                property_value,
                shipsFrom, is_disabled);

            const { data } = state.productData
            const { product_variants } = data

            let getVariant
            let matchedProp = []
            // if (parent) {
            // disable = globallyDisabledProperties[property_name]?.includes(property_value) ?? false

            product_variants.map(variant => {
                variant.variant_properties.map((prop, i) => {
                    if (prop.shopify_property_label === property_name && prop.shopify_property_value === property_value || (prop.shopify_property_label === 'Ships From' && prop.shopify_property_value === shipsFrom)) {
                        matchedProp.push(true)
                    }

                })
                if (matchedProp.length === 2) {

                    getVariant = variant
                    matchedProp = []

                    return
                }
                matchedProp = []

            })

            // console.log('whole saler is ships array===>', JSON.parse(JSON.stringify(getVariant)));

            const updatedVariants = product_variants.map(item => {
                // const matched = variant.find(v => v.aliexpress_sku === item.aliexpress_sku)
                if (item.aliexpress_sku === getVariant.aliexpress_sku) {
                    return {
                        ...item,
                        is_disabled: is_disabled
                    };
                }
                return item;
            });

            state.productData.data.product_variants = updatedVariants;


        },
        setToUpdateImageDetails: (

            state, action
        ) => {
            const { to,
                index,
                key, imageUrl } = action.payload
            state.toUpdateImageDetails = {
                to,
                index,
                key,
                imageUrl
            }
        },
        updateVariantImageFromCanvaEdit: (state, action) => {
            const { existingUrl, newUrl } = action.payload;
            const { data } = state.productData;
            const { product_variants } = data;

            const updatedPropertiesArray = product_variants.map((item) => {
                return {
                    ...item,
                    variant_properties: item.variant_properties.map((prop) => {
                        if (prop.hasOwnProperty('image')) {
                            console.log('images properties::', JSON.stringify(prop));

                        }

                        if (prop.hasOwnProperty('image') && prop?.image === existingUrl) {
                            return {
                                ...prop,
                                image: newUrl,
                            };
                        }

                        return prop;
                    }),
                };
            });

            // Assign the new array to the state
            state.productData = {
                ...state.productData,
                data: {
                    ...data,
                    product_variants: updatedPropertiesArray,
                },
            };
            state.changeVariantImageKey = undefined;
        },
        setArragedVariantsData: (state, action) => {
            state.variantsArrangedIndexesObject = action.payload
        },
        changePositionOfVariant: (state, action) => {
            const { from, to } = action.payload;


            const { data } = state.productData;
            const { product_variants } = data;
            const updatedVariants = product_variants
            const fromElement = updatedVariants[from];
            const toElement = updatedVariants[to];
            updatedVariants[to] = fromElement
            updatedVariants[from] = toElement

            state.productData = {
                ...state.productData,
                data: {
                    ...data,
                    product_variants: updatedVariants,
                },
            };
            // the "from" element will take place of "to" element and vice versa the from and to are indexs of array change the positions of the elements 
        },
        changePositionOfVariantInOtherState: (state, action) => {
            const { from, to, property } = action.payload;

            const propertyArray = state.variantsArrangedIndexesObject[property];
            const fromElement = propertyArray[from];
            const toElement = propertyArray[to];
            propertyArray[to] = fromElement
            propertyArray[from] = toElement
            state.variantsArrangedIndexesObject = {
                ...state.variantsArrangedIndexesObject,
                [property]: propertyArray
            }
            console.log('rearranged::', propertyArray, JSON.stringify(state.variantsArrangedIndexesObject));
        }
    },

});

export const {
    disableSpecificShipsfromOnFirstStep,
    setProductDataInState,
    resetProductDataInState,
    changeProductBullets,
    setMatchingVariant,
    editSalePrice,
    editRegularPrice,
    setVariantProperties,
    setSelectedVariantProperties,
    changeLabelNameOfProperties,
    disableVariant,
    disableSpecificProperties,
    disablePropertyOfVariant,
    changeLabelOfProperties,
    changePriceAndDiscount,
    changePriceAndDiscountOfVariants,
    changePriceAndDiscountOfSpecific,
    setPriceOfAllVariants,
    setMediaImages,
    changeSelectedVariantImage,
    setChangeVariantImageKey,
    changeSKU,
    enableDiscountOfVariants,
    enableDiscountOfSpecific,
    setEditViewStyle,
    recalculateTheEnabledVariants,
    setToUpdateImageDetails,
    updateVariantImageFromCanvaEdit,
    changePositionOfVariant,
    setArragedVariantsData,
    changePositionOfVariantInOtherState
} = productVariantsSlice.actions;

export default productVariantsSlice.reducer;


