import varinatsShippedFrom from "./productVariantsHandlers/VariantsShippedFrom";

export default function checkFilteredEnabledVariants({ product_variants, shipsFrom, shipsFromLength }) {

    let filteredVariantsBasedOnShipsFrom = [];
    if (shipsFromLength != 0) {
        filteredVariantsBasedOnShipsFrom = varinatsShippedFrom(product_variants, shipsFrom)
        console.log(' ************filteredVariantsBasedOnShipsFrom length,******');
        console.log('shipsFromArray:', 'variants filtered:', filteredVariantsBasedOnShipsFrom.length);
        console.log(' ************filteredVariantsBasedOnShipsFrom length,******');
    }
    // ***************
    const checkFromVariants = filteredVariantsBasedOnShipsFrom.length != 0 ? filteredVariantsBasedOnShipsFrom : product_variants
    return checkFromVariants

}