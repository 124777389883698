 import { createSlice } from "@reduxjs/toolkit";




const staticMediaImagesSlice = createSlice({
    name: "staticMediaImagesSlice",

    initialState: {
        staticMediaImages:[]
    },
    reducers: {
        setStaticMediaImagesInStateimport: (state, action) => {
           state.staticMediaImages =action.payload;
        },

    }

});

export const {
    setStaticMediaImagesInStateimport,

} = staticMediaImagesSlice.actions;

export default staticMediaImagesSlice.reducer;

