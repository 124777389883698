
export default function checKParentSaleIfEnabledInAnyChild({ parentChild, productData, propertyValue }) {
    if (parentChild) {
        const { data } = productData;
        const { product_variants } = data;
        let variants = [];
        product_variants.map(item => {
            item.variant_properties.map(prop => {
                if (prop.shopify_property_value === propertyValue
                ) {
                    variants.push(item)
                }
            });
        });
        const findEnabledSalePrice = variants.find((item) => item.enableSalePrice === true)
        return findEnabledSalePrice ? true:false

    }
    return false
}