
export default function getAliExpressPrices(productData) {
    const { data } = productData
    const { aliexpress_response } = data
    const { ae_item_sku_info_dtos } = aliexpress_response
    const { ae_item_sku_info_d_t_o } = ae_item_sku_info_dtos
    let pricesObject = {}
    ae_item_sku_info_d_t_o.forEach(f => {

        pricesObject[f.sku_attr === '' ? f.sku_id : f.sku_attr] = {
            price: (f.offer_sale_price && f.offer_sale_price > 0) || f.offer_sale_price !== "" ? f.offer_sale_price :
                f.sku_price || 0,
            sale: f.offer_sale_price || 0,
            actualPrice: f.sku_price || 0
        }

    })
    return pricesObject


}