import React, { useState, useEffect } from "react";
import { message } from "antd";
import "./dashboard.css";
import LoaderWrapper from "../LoaderWrapper/LoaderWrapper";
import Layout from "../Layout";
import ConnectStore from "../ConnectStore";
import TaskAnalysis from "./TaskAnalysis/index.jsx";
import { URLS } from "../../URLS.js";
import currentAuthenticatedUser, {
  getCurrentAuthenticatedSession,
} from "../../authUser.js";
import { useSearchParams } from "react-router-dom";
import ErrorComponent from "./Error/index.jsx";
import Wrapper from "./Wrapper";
import { useDispatch } from "react-redux";
import { setStoreInformation } from "../../redux/slices/auth-user-slice.js";
import { callBackendAPI } from "../../helpers/apiCalls.js";


const Dashboard = ({ }) => {
  const dispatch = useDispatch()
  const [aliExpressLoading, setAliExpressLoading] = useState(true);
  const [shopifyLoading, setShopifyLoading] = useState(true);
  const [isShopifyConnected, setIsShopifyConnect] = useState(true);
  const [isAliExpressConnected, setIsAliExpressConnect] = useState(true);
  // const [isAliExpressAuthorize, setIsAliExpressAuthorize] = useState(false);
  const [error, setError] = useState(false); // State to store error message
  const [popupMessage, setPopupMessage] = useState("");
  const [storeData, setStoreData] = useState("");
  const [token, setToken] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [userId, setUserId] = useState("");
  const [disconnectAliExpressLoading, setDisconnectAliExpressLoading] = useState(false);
  const [reloadConnections, setReloadConnections] = useState(false);
  // const [userId, setUserId] = useState("");
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const checkAliExpressConnection = async () => {
      const { userId, loginId } = await currentAuthenticatedUser();
      setUserId(userId);
      const accessToken = await getCurrentAuthenticatedSession();
      setAccessToken(accessToken);

      let messageParam = searchParams.get("message");

      if (messageParam) {
        message.info(messageParam); // Display message as Ant design info popup
      }
      // 

      const response = await fetch(
        `${URLS.BACKEND_API}/aliexpress/connect-check?userId=${userId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`, // Replace with your auth token format
          },
        }
      );
      setAliExpressLoading(false);

      const data = await response?.json();
      setIsAliExpressConnect(data.status);
      // setIsAliExpressAuthorize(data.authorize)
    };

    const checkShopifyConnection = async () => {
      setShopifyLoading(true);
      let shopParam = searchParams.get("shop");

      if (!shopParam) {
        shopParam = "";
      }
      const { userId, loginId } = await currentAuthenticatedUser(); // Await to get the resolved value
      const accessToken = await getCurrentAuthenticatedSession();
      try {
        const response = await fetch(
          `${URLS.BACKEND_API}/store/auth_check?shop=${shopParam}&userId=${userId}&cognito_email=${encodeURIComponent(loginId)}`,
          {
            method: "GET",
            headers: userId
              ? {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`, // Replace with your auth token format
              }
              : {
                "Content-Type": "application/json",
              },
          }
        );
        const data = await response.json();
        localStorage.setItem("email", data?.storeData?.email);
        localStorage.setItem("shop_owner", data?.storeData?.shop_owner);
        localStorage.setItem("shopifyConnected",  data.connected);
        localStorage.setItem("shopName", data.storeData.shop);
        // saving the shops owner and email in redux store
        const shopifyConnectionDetails = {
          connected : data.connected,
          name:data.storeData.shop
        }
        console.log(shopifyConnectionDetails,'datatatata');
        
        dispatch(setStoreInformation({ shop_owner: data?.storeData?.shop_owner, email: data?.storeData?.email, shopifyConnectionDetails}))

        setTimeout(() => {
          setPopupMessage(""); // This will hide the popup after 3 seconds
        }, 3000);




        if (data.token) {
          localStorage.setItem("token", data.token);
          setToken(data.token);
        }

        if (data.connected) {
          // message.success(data?.message )
          setIsShopifyConnect(true);
          localStorage.setItem("shopName", data.storeData.shop);
          localStorage.setItem("shopId", data.storeData.shop_id);

          setStoreData(data.storeData.shop);
        } else if (data.error) {

          setIsShopifyConnect(false);
          setError(data.message); // Set error message if present
        } else {
          setIsShopifyConnect(false); // User needs to connect the store
        }
      } catch (error) {
        message.error('Unexpected error occured!')

        console.error("Error checking shop:", error);
        // Handle errors appropriately (e.g., display an error message)
      } finally {
        setShopifyLoading(false);
      }
    };

    checkShopifyConnection();
    checkAliExpressConnection();
  }, [reloadConnections]);
  const handleDisconnectAliExpress = async () => {
    setDisconnectAliExpressLoading(true)
    try {
      const response = await callBackendAPI("aliexpress/disconnect", "POST", {}, { payload: {} });
      if (response?.status) {
        setReloadConnections(!reloadConnections)
        setAliExpressLoading(true)
        setShopifyLoading(true)
      }
      else {
        message.error('Error disconnecting aliexpress!')

      }
    } catch (error) {
      message.error(`Cannot disconnect due to ${error.message}`, 2);
    } finally {
      setDisconnectAliExpressLoading(false)
    

    }
    console.log('disconnect..');


  }

  return (
    // <LoaderWrapper loading={shopifyLoading && aliExpressLoading} loadingText="Getting Dashboard Ready...">
    <>
      <Popup message={popupMessage} />
      <Wrapper>
        <TaskAnalysis
          shopifyLoading={shopifyLoading}
          aliExpressLoading={aliExpressLoading}
          shopifyData={storeData}
          aliExpressData={null}
          shopifyAuthCheck={isShopifyConnected}
          aliexpressAuthCheck={isAliExpressConnected}
          handleDisconnectAliExpress={handleDisconnectAliExpress}
          disconnectAliExpressLoading={disconnectAliExpressLoading} setDisconnectAliExpressLoading={setDisconnectAliExpressLoading}
        // isAliExpressAuthorize={isAliExpressAuthorize}
        />
      </Wrapper>
      {/* <Layout activeOption="Dashboard">

          <TaskAnalysis shopifyLoading={shopifyLoading} aliExpressLoading={aliExpressLoading}  shopifyData={storeData} aliExpressData={null} shopifyAuthCheck={isShopifyConnected} aliexpressAuthCheck={isAliExpressConnected} />
      </Layout> */}
    </>

    // </LoaderWrapper>
  );
};

function Popup({ message }) {
  if (!message) return null;
  return (
    <div
      style={{
        position: "absolute",
        top: "10%",
        left: "50%",
        transform: "translateX(-50%)",
        background: "white",
        padding: "20px",
        zIndex: 1000,
        borderRadius: "10px",
        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
      }}
    >
      {message}
    </div>
  );
}

export default Dashboard;
