import { LoadingOutlined } from "@ant-design/icons";
import { setProductDataInState } from "../../redux/slices/product-variants-slice";
import { useEffect, useState } from "react";
import { callBackendAPI } from "../../helpers/apiCalls";
import { useDispatch, useSelector } from "react-redux";
import { message, Spin } from "antd";
import ProductEditView from "./ProductEditView";
import { Navigate, useNavigate, useParams } from "react-router";

const ImportingLoading = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate()
    const [error, setError] = useState(false);

    const { productData } = useSelector(store => store.productVariants)

    useEffect(() => {
        console.log('product data', productData);


        setTimeout(() => {
            if (!productData) {
                setLoading(false)
                setError(true)
            } else {
                setLoading(false)
            }
        }, 1000);

    }, [])

    const antIcon = (
        <LoadingOutlined
            className="custom-spin-icon"
            style={{ fontSize: "50px" }}
            spin
        />
    );



    return (
        loading ? (
            <div className="spinner-overlay">
                <div className="spinner-container">
                    <Spin className="custom-spin" indicator={antIcon} />
                </div>
            </div>
        ) : !error ? (
            <ProductEditView />
        ) : <Navigate to={'/'}/>
    )
}
export default ImportingLoading